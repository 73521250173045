import { Dispatch } from 'redux';
import { RestClient } from '../../services';
import type { ActionPayload } from '..';

export type Status = 'idle' | 'pending' | 'succeeded' | 'failed';
export type Callback = (err: Error | null, result?: AnyObj | AnyObj[] | null) => void;

export interface Response extends AnyObj {}

interface RestState {
    loading: Status,
    loadingError: string | null,
    loadingOne: Status,
    loadingOneError: string | null,
    updating: Status,
    updatingError: string | null,
    creating: Status,
    creatingError: string | null,
    deleting: Status,
    deletingError: string | null,
    duplicating: Status,
    duplicatingError: string | null,
    created: boolean,
    deleted: boolean,
    duplicated: boolean,
    [key: string]: any
};

const resetSlice = (service: RestClient) => {
    const property     = service.resource;
    const listProperty = service.resources;

    const RESET = `yoda/${property}/RESET`;

    const resetReducer: Reducer = (state: RestState, action: ActionPayload) => {
        switch (action.type) {
            case RESET:
                if (action.onlyItem)
                    return {
                        ...state,
                        [property]: null,
                        loadingOne: 'idle',
                        loadingOneError: null,
                    }
                return {
                    ...state,
                    [property]: null,
                    [listProperty]: [],
                    loading: 'idle',
                    loadingError: null,
                    loadingOne: 'idle',
                    loadingOneError: null,
                    creating: 'idle',
                    creatingError: null,
                    updating: 'idle',
                    updatingError: null,
                    deleting: 'idle',
                    deletingError: null,
                    duplicating: 'idle',
                    duplicatingError: null,
                    created: false,
                    deleted: false,
                    duplicated: false,
                }
            default:
                return state;
        }
    };

    const resetAction = (onlyItem?: boolean) => { return { type: RESET, onlyItem } };

    return { resetReducer, resetAction };
}

const startCreateSlice = (service: RestClient) => {
    const property = service.resource;

    const START_CREATING_ITEM = `yoda/${property}/START_CREATING_ITEM`;

    const startCreateReducer: Reducer = (state: RestState, action: ActionPayload) => {
        switch (action.type) {
            case START_CREATING_ITEM:
                return {
                    ...state,
                    [property]: new service.model(action.initial || {}),
                    loading: 'idle',
                    loadingError: null,
                    loadingOne: 'idle',
                    loadingOneError: null,
                    creating: 'idle',
                    creatingError: null,
                    created: false,
                    deleted: false,
                }
            default:
                return state;
        }
    };

    const startCreateAction = (initial?: AnyObj) => { return { type: START_CREATING_ITEM, initial } };

    return { startCreateReducer, startCreateAction };
}

const createSlice = (service: RestClient) => {
    const property     = service.resource;
    const listProperty = service.resources;

    const CREATING_ITEM         = `yoda/${property}/CREATING_ITEM`;
    const CREATING_ITEM_SUCCESS = `yoda/${property}/CREATING_ITEM_SUCCESS`;
    const CREATING_ITEM_FAILURE = `yoda/${property}/CREATING_ITEM_FAILURE`;

    const createReducer: Reducer = (state: RestState, action: ActionPayload) => {
        switch (action.type) {
            case CREATING_ITEM:
                return {
                    ...state,
                    creating: 'pending',
                    creatingError: null,
                };
            case CREATING_ITEM_SUCCESS:
                return {
                    ...state,
                    creating: 'succeeded',
                    creatingError: null,
                    created: true,
                    [property]: action[property],
                    [listProperty]: (state[listProperty] || []).push(action[property]) && state[listProperty]
                };
            case CREATING_ITEM_FAILURE:
                return {
                    ...state,
                    creating: 'failed',
                    creatingError: action.error.message,
                    created: false,
                };
            default:
                return state;
        }
    }

    function creatingItem() { return { type: CREATING_ITEM } }
    function creatingItemSuccess(item: AnyObj) { return { type: CREATING_ITEM_SUCCESS, [property]: item } }
    function creatingItemFailure(err: Error) { return { type: CREATING_ITEM_FAILURE, error: err } }
    const createAction = (item: AnyObj, params?: AnyObj, callback?: Callback) => {
        return (dispatch: Dispatch) => {
            dispatch(creatingItem());
            return service.create(item, params)
                .then((data: Response) => {
                    const item = data[property];
                    dispatch(creatingItemSuccess(item));
                    callback && callback(/*err*/null, item);
                })
                .catch((err: Error) => {
                    dispatch(creatingItemFailure(err))
                    callback && callback(err);
                });
        }
    };

    return { createReducer, createAction };
};

const cancelCreateSlice = (service: RestClient) => {
    const property = service.resource;

    const CANCEL_CREATING_ITEM = `yoda/${property}/CANCEL_CREATING_ITEM`;

    const cancelCreateReducer: Reducer = (state: RestState, action: ActionPayload) => {
        switch (action.type) {
            case CANCEL_CREATING_ITEM:
                return {
                    ...state,
                    [property]: null,
                    loading: 'idle',
                    loadingError: null,
                    loadingOne: 'idle',
                    loadingOneError: null,
                    creating: 'idle',
                    creatingError: null,
                    created: false,
                    deleted: false,
                }
            default:
                return state;
        }
    };

    const cancelCreateAction = () => { return { type: CANCEL_CREATING_ITEM } };

    return { cancelCreateReducer, cancelCreateAction };
}

const getSlice = (service: RestClient) => {
    const property     = service.resource;
    const listProperty = service.resources;

    const LOADING_ITEM         = `yoda/${property}/LOADING_ITEM`;
    const LOADING_ITEM_SUCCESS = `yoda/${property}/LOADING_ITEM_SUCCESS`;
    const LOADING_ITEM_FAILURE = `yoda/${property}/LOADING_ITEM_FAILURE`;

    const getReducer: Reducer = (state: RestState, action: ActionPayload) => {
        switch (action.type) {
            case LOADING_ITEM:
                return {
                    ...state,
                    loadingOne: 'pending',
                    loadingOneError: null,
                    [`${property}SuccessfullyCreated`]: false,
                    [`${property}SuccessfullyDeleted`]: false,
                };
            case LOADING_ITEM_SUCCESS:
                return {
                    ...state,
                    loadingOne: 'succeeded',
                    loadingOneError: null,
                    [property]: action[property],
                    [listProperty]: (state[listProperty] || []).map((p: AnyObj) => {
                        if (p._id === action[property]._id)
                            return action[property];
                        return p;
                    })
                };
            case LOADING_ITEM_FAILURE:
                return {
                    ...state,
                    loadingOne: 'failed',
                    loadingOneError: action.error.message
                };
            default:
                return state;
        }
    };

    function loading() { return { type: LOADING_ITEM } }
    function loadingSuccess(item: AnyObj) { return { type: LOADING_ITEM_SUCCESS, [property]: item } }
    function loadingFailure(err: Error) { return { type: LOADING_ITEM_FAILURE, error: err } }

    const getAction = (_id: string, callback?: Callback) => {
        return (dispatch: Dispatch) => {
            dispatch(loading());
            return service.get(_id)
                .then((data: Response) => {
                    const item = data[property];
                    dispatch(loadingSuccess(item));
                    callback && callback(/*err*/null, item);
                })
                .catch((err: Error) => {
                    console.error(err);
                    dispatch(loadingFailure(err))
                    callback && callback(err);
                });
        }
    };

    return { getReducer, getAction };
};

const listSlice = (service: RestClient) => {
    const property = service.resources;

    const LOADING_ITEMS         = `yoda/${property}/LOADING_ITEMS`;
    const LOADING_ITEMS_SUCCESS = `yoda/${property}/LOADING_ITEMS_SUCCESS`;
    const LOADING_ITEMS_FAILURE = `yoda/${property}/LOADING_ITEMS_FAILURE`;

    const listReducer: Reducer = (state: RestState, action: ActionPayload) => {
        switch (action.type) {
            case LOADING_ITEMS:
                return {
                    ...state,
                    loading: 'pending',
                    loadingError: null,
                };
            case LOADING_ITEMS_SUCCESS:
                return {
                    ...state,
                    loading: 'succeeded',
                    loadingError: null,
                    created: false,
                    deleted: false,
                    [property]: action[property]
                };
            case LOADING_ITEMS_FAILURE:
                return {
                    ...state,
                    loading: 'failed',
                    created: false,
                    deleted: false,
                    loadingError: action.error.message
                };
            default:
                return state;
        }
    };

    function loading() { return { type: LOADING_ITEMS } }
    function loadingSuccess(items: AnyObj[]) { return { type: LOADING_ITEMS_SUCCESS, [property]: items } }
    function loadingFailure(err: Error) { return { type: LOADING_ITEMS_FAILURE, error: err } }
    const listAction = (filters?: AnyObj, callback?: Callback) => {
        return (dispatch: Dispatch) => {
            dispatch(loading());
            return service.list(filters)
                .then((data: Response) => {
                    const items = data[property];
                    dispatch(loadingSuccess(items));
                    callback && callback(/*err*/null, items);
                })
                .catch((err: Error) => {
                    dispatch(loadingFailure(err))
                    callback && callback(err);
                });
        }
    };

    return { listReducer, listAction };
}

const updateSlice = (service: RestClient) => {
    const property     = service.resource;
    const listProperty = service.resources;

    const UPDATING_ITEM                  = `yoda/${property}/UPDATING_ITEM`;
    const UPDATING_ITEM_OPTIMIST_SUCCESS = `yoda/${property}/UPDATING_ITEM_OPTIMIST_SUCCESS`;
    const UPDATING_ITEM_SUCCESS          = `yoda/${property}/UPDATING_ITEM_SUCCESS`;
    const UPDATING_ITEM_FAILURE          = `yoda/${property}/UPDATING_ITEM_FAILURE`;

    const updateReducer: Reducer = (state: RestState, action: ActionPayload) => {
        switch (action.type) {
            case UPDATING_ITEM:
                return {
                    ...state,
                    updating: 'pending',
                    updatingError: null,
                };
            case UPDATING_ITEM_OPTIMIST_SUCCESS:
                const newItem = action.patch ? new service.model({
                    ...(state[property] || {}),
                    ...(action[property] || {})
                }) : action[property];

                return {
                    ...state,
                    [`old_${property}`]: state[property],
                    [property]: newItem,
                    [listProperty]: (state[listProperty] || []).map((p: AnyObj) => {
                        if (p._id === action[property]._id)
                            return newItem;
                        return p;
                    })
                };
            case UPDATING_ITEM_SUCCESS:
                return {
                    ...state,
                    updating: 'succeeded',
                    updatingError: null,
                    [property]: action[property],
                    [listProperty]: (state[listProperty] || []).map((p: AnyObj) => {
                        if (p._id === action[property]._id)
                            return action[property];
                        return p;
                    })
                };
            case UPDATING_ITEM_FAILURE:
                return {
                    ...state,
                    [property]: state[`old_${property}`] || state[property],
                    updating: 'failed',
                    updatingError: action.error.message
                };
            default:
                return state;
        }
    };

    function updatingItem() { return { type: UPDATING_ITEM } }
    function updatingItemOptimistSuccess(item: AnyObj, patch: boolean) { return { type: UPDATING_ITEM_OPTIMIST_SUCCESS, [property]: item, patch } }
    function updatingItemSuccess(item: AnyObj) { return { type: UPDATING_ITEM_SUCCESS, [property]: item } }
    function updatingItemFailure(err: Error) { return { type: UPDATING_ITEM_FAILURE, error: err } }
    const updateAction = (item: AnyObj, patch?: boolean, optimistic?: boolean, callback?: Callback) => {
        return (dispatch: Dispatch) => {
            dispatch(updatingItem());

            if (optimistic)
                dispatch(updatingItemOptimistSuccess(item, !!patch));

            return service.update(item, patch)
                .then((data: Response) => {
                    const item = data[property];
                    dispatch(updatingItemSuccess(item));
                    callback && callback(/*err*/null, item);
                })
                .catch((err: Error) => {
                    dispatch(updatingItemFailure(err))
                    callback && callback(err);
                });
        }
    };

    return { updateReducer, updateAction };
};

const destroySlice = (service: RestClient) => {
    const property     = service.resource;
    const listProperty = service.resources;

    const DELETING_ITEM         = `yoda/${property}/DELETING_ITEM`;
    const DELETING_ITEM_SUCCESS = `yoda/${property}/DELETING_ITEM_SUCCESS`;
    const DELETING_ITEM_FAILURE = `yoda/${property}/DELETING_ITEM_FAILURE`;

    const deleteReducer: Reducer = (state: RestState, action: ActionPayload) => {
        switch (action.type) {
            case DELETING_ITEM:
                return {
                    ...state,
                    deleting: 'pending',
                    deletingError: null,
                    created: false,
                    deleted: false,
                };
            case DELETING_ITEM_SUCCESS:
                return {
                    ...state,
                    deleting: 'succeeded',
                    deletingError: null,
                    deleted: true,
                    [property]: null,
                    [listProperty]: (state[listProperty] || []).filter((p: AnyObj) => p._id !== action[property])
                };
            case DELETING_ITEM_FAILURE:
                return {
                    ...state,
                    deleting: 'failed',
                    deletingError: action.error.message,
                    deleted: false,
                };
            default:
                return state;
        }
    };

    function deletingItem() { return { type: DELETING_ITEM } }
    function deletingItemSuccess(item: string) { return { type: DELETING_ITEM_SUCCESS, [property]: item } }
    function deletingItemFailure(err: Error) { return { type: DELETING_ITEM_FAILURE, error: err } }
    const deleteAction = (_id: string, callback?: Callback) => {
        return (dispatch: Dispatch) => {
            dispatch(deletingItem());
            return service.delete(_id)
                .then((data: Response) => {
                    dispatch(deletingItemSuccess(_id));
                    callback && callback(/*err*/null);
                })
                .catch((err: Error) => {
                    dispatch(deletingItemFailure(err))
                    callback && callback(err);
                });
        }
    };

    return { deleteReducer, deleteAction };
};

const duplicateSlice = (service: RestClient) => {
    const property     = service.resource;
    const listProperty = service.resources;

    const DUPLICATING_ITEM         = `yoda/${property}/DUPLICATING_ITEM`;
    const DUPLICATING_ITEM_SUCCESS = `yoda/${property}/DUPLICATING_ITEM_SUCCESS`;
    const DUPLICATING_ITEM_FAILURE = `yoda/${property}/DUPLICATING_ITEM_FAILURE`;

    const duplicateReducer: Reducer = (state: RestState, action: ActionPayload) => {
        switch (action.type) {
            case DUPLICATING_ITEM:
                return {
                    ...state,
                    duplicating: 'pending',
                    duplicatingError: null,
                };
            case DUPLICATING_ITEM_SUCCESS:
                return {
                    ...state,
                    duplicating: 'succeeded',
                    duplicatingError: null,
                    duplicated: true,
                    [property]: action[property],
                    [listProperty]: (state[listProperty] || []).push(action[property]) && state[listProperty]
                };
            case DUPLICATING_ITEM_FAILURE:
                return {
                    ...state,
                    duplicating: 'failed',
                    duplicatingError: action.error.message,
                    duplicated: false,
                };
            default:
                return state;
        }
    }

    function duplicatingItem() { return { type: DUPLICATING_ITEM } }
    function duplicatingItemSuccess(item: AnyObj) { return { type: DUPLICATING_ITEM_SUCCESS, [property]: item } }
    function duplicatingItemFailure(err: Error) { return { type: DUPLICATING_ITEM_FAILURE, error: err } }
    const duplicateAction = (item: AnyObj, callback?: Callback) => {
        return (dispatch: Dispatch) => {
            dispatch(duplicatingItem());
            return service.duplicate(item)
                .then((data: Response) => {
                    const item = data[property];
                    dispatch(duplicatingItemSuccess(item));
                    callback && callback(/*err*/null, item);
                })
                .catch((err: Error) => {
                    dispatch(duplicatingItemFailure(err))
                    callback && callback(err);
                });
        }
    };

    return { duplicateReducer, duplicateAction };
};

export const createRestSlices = (service: RestClient) => {
    const property     = service.resource;
    const listProperty = service.resources;

    const initialState: RestState = {
        loading: 'idle',
        loadingError: null,
        loadingOne: 'idle',
        loadingOneError: null,
        creating: 'idle',
        creatingError: null,
        updating: 'idle',
        updatingError: null,
        deleting: 'idle',
        deletingError: null,
        duplicating: 'idle',
        duplicatingError: null,
        [property]: null,
        [listProperty]: [],
        created: false,
        deleted: false,
        duplicated: false,
    };

    return {
        initialState,
        ...resetSlice(service),
        ...startCreateSlice(service),
        ...createSlice(service),
        ...cancelCreateSlice(service),
        ...getSlice(service),
        ...listSlice(service),
        ...updateSlice(service),
        ...destroySlice(service),
        ...duplicateSlice(service)
    };
};

type Reducer = (state: RestState, action: ActionPayload) => RestState;
export const applyReducers = (state: RestState, action: ActionPayload, reducers: Reducer[]) => {
    return reducers.reduce(
        (state: RestState, reducer: Reducer) => reducer(state, action),
        state
    );
};
