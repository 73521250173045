import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch, useAppSelector } from '../../redux';
import { addProjectMembers, removeProjectMembers, loadUsers } from '../../redux';
import { Transition } from '@headlessui/react'
import { Avatar, Modal } from 'flowbite-react';
import { User } from '../../models';
import { Ability, Icon, Tooltip, UserAvatar, Button } from '..';

interface ProjectMembersProps {};

const ProjectMembers = (props: ProjectMembersProps) => {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const searchInput = useRef<HTMLInputElement>(null);

    const [search, setSearch]       = useState("");
    const [modalVisible, showModal] = useState(false);

    const { user }  = useAppSelector((state: RootState) => state.auth);
    const { users } = useAppSelector((state: RootState) => state.users);
    const { project, membersUpdate } = useAppSelector((state: RootState) => state.projects);

    const toggleMember = (member: User) => {
        if (!user?.can('project:members:edit'))
            return;

        if (project.hasMember(member))
            dispatch(removeProjectMembers(project._id, [member._id]));
        else
            dispatch(addProjectMembers(project._id, [member._id]));
    };

    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch]);

    const members: User[] = React.useMemo(() => {
        return users?.filter((u: User) => (u._id !== user?._id) && project.hasMember(u)).sort((a: User, b: User) => {
            return a.rfullname() > b.rfullname() ? 1 : -1;
        });
    }, [project, users, user]);

    const notMembers: User[] = React.useMemo(() => {
        return users?.filter((u: User) => {
            if (project.hasMember(u))
                return false;
            if (!(search?.length >= 3))
                return true;
            return u.fullname().match(new RegExp(search, 'gi'));
        }).sort((a: User, b: User) => {
            return a.rfullname() > b.rfullname() ? 1 : -1;
        });
    }, [project, users, search]);

    if (!project || !users)
        return null;

    const avatar = (user: User, locked?: boolean) => {
        return (
            <Tooltip key={user._id} content={user.fullname()} placement="bottom">
                <div>
                    <Avatar
                        key={user._id}
                        rounded
                        bordered
                        stacked
                        color={project.hasMember(user) ? 'purple' :'light'}
                        size="xs"
                        placeholderInitials={user.getInitials()}
                        onClick={() => showModal(true)}
                        className="cursor-pointer text-xs"
                    />
                </div>
            </Tooltip>
        );
    }

    if (searchInput?.current)
        searchInput.current.focus();

    return (
        <div className="flex items-center py-2 group">
            {/* me */}
            <div className="flex">
                {avatar(user, /*locked*/true)}
            </div>

            {/* members */}
            {members?.length > 0 && (
                <div className="flex border-l ml-2 pl-2">
                    {members.map(user => avatar(user))}
                </div>
            )}

            {/* not members */}
            <Ability can="project:members:edit">
                <>
                    {/* edit button */}
                    <div className="hidden group-hover:block flex items-center pl-2 cursor-pointer" onClick={() => showModal(!modalVisible)}>
                        <Button title={t('projects.edit_members')} icon="edit" color="primary" small onClick={() => showModal(true)} />
                    </div>

                    {modalVisible && (
                        <Modal dismissible onClose={() => showModal(false)} size="4xl" show={modalVisible} className="focus:outline-0">
                            <Modal.Header className="w-full" theme={{ "title": "text-xl font-medium text-gray-900 dark:text-white w-full" }}>
                                <h3 className="font-bold">
                                    <Icon type="user" className="inline" size={ 5 } />&nbsp;
                                    {t('projects.update_members_title')}
                                </h3>
                            </Modal.Header>
                            <Modal.Body style={{ minHeight: '500px' }}>
                                <div className="flex flex-col justify-center md:flex-row gap-5 md:gap-0">
                                    <div className="flex-1 flex flex-col gap-2 md:border-r pr-0 md:pr-5">
                                        <h3 className="font-bold">{t('projects.actual_members')}</h3>
                                        <div key={user._id} className="max-h-[450px] overflow-y-auto">
                                            {members?.map((user: User) => (
                                                <div key={user._id} className="bg-white border p-2 rounded dark:border-gray-700 dark:bg-gray-800 flex gap-2 flex-row justify-between items-center w-full mb-2">
                                                    <div className="flex gap-2">
                                                        <UserAvatar user={user} hideInitials size={8} />
                                                        <div className="flex-row text-sm font-medium">
                                                            {user?.rfullname()}
                                                            <div className="text-xs text-gray-500 font-light">{user.email}</div>
                                                        </div>
                                                    </div>
                                                    <Button title="" icon={membersUpdate === 'pending' ? 'loading' : 'close'} iconSize={4} color="danger" onClick={() => toggleMember(user)} small />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flex-1 flex flex-col gap-2 pl-0 md:pl-5">
                                        <h3 className="font-bold">{t('projects.add_members')}</h3>
                                        <div className="relative flex">
                                            <input
                                                type="text"
                                                value={search}
                                                onChange={e => setSearch(e.target.value)}
                                                ref={searchInput}
                                                className="w-full h-9 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 rounded-md focus:ring-gray-300 border-0 focus:border-0 py-0 pr-8"
                                            />
                                            <Icon type="search" color="gray-600" className="absolute top-2.5 right-2.5" />
                                        </div>
                                        <div key={user._id} className="max-h-[400px] overflow-y-auto">
                                            {notMembers?.map((user: User) => (
                                                <div key={user._id} className="bg-white border p-2 rounded dark:border-gray-700 dark:bg-gray-800 flex gap-2 flex-row justify-between items-center w-full mb-2">
                                                    <div className="flex gap-2">
                                                        <UserAvatar user={user} hideInitials size={8} />
                                                        <div className="flex-row text-sm font-medium">
                                                            {user?.rfullname()}
                                                            <div className="text-xs text-gray-500 font-light">{user.email}</div>
                                                        </div>
                                                    </div>
                                                    <Button title="" icon={membersUpdate === 'pending' ? 'loading' : 'plus'} iconSize={4} color="primary" onClick={() => toggleMember(user)} small />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    )}
                </>
            </Ability>
        </div>
    );
};

export default ProjectMembers;
