import React, { useState } from 'react';
import { parseDiff, Diff, Hunk } from 'react-diff-view';
import { Icon } from '../Icon';
import 'react-diff-view/style/index.css';
import 'prismjs/themes/prism.css';
import 'prism-color-variables/variables.css';
import 'prism-color-variables/themes/duracula.css';

interface DiffViewProps {
    diff: string;
}

function DiffView(props: DiffViewProps) {
    const { diff } = props;
    const [collapsed, setCollapsed] = useState<AnyObj>({});

    const files = React.useMemo(() => parseDiff(diff), [ diff ]);

    const toggle = (key: string) => {
        setCollapsed({
            ...collapsed,
            [key]: !collapsed[key]
        });
    }

    const renderFile = (props: any) => {
        const { oldPath, oldRevision, newRevision, type, hunks } = props;

        const key = oldRevision + '-' + newRevision;
        return (
            <div className="text-xs border mt-2 p-0">
                <div className="text-sm p-2 border-b font-bold d-flex cursor-pointer hover:bg-gray-100" onClick={() => toggle(key) }>
                    <Icon type={ collapsed[key] ? 'right' : 'down'} className="inline" />&nbsp;
                    { oldPath }
                </div>
                {!collapsed[key] && (
                    <Diff key={ key } viewType="split" diffType={type} hunks={hunks} optimizeSelection>
                        {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
                    </Diff>
                )}
            </div>
        );
    }

    return (
        <div>
            { files.map(renderFile) }
        </div>
    );
}

export default DiffView;
