import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux';
import { loadUsers } from '../../../redux';
import { Link, useNavigate } from "react-router-dom";
import { Ability, Button, Icon, UserAvatar } from '../../../components';
import { Table } from 'flowbite-react';
import { User } from '../../../models';

function UsersList() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const searchInput         = useRef<HTMLInputElement>(null);
    const [search, setSearch] = useState("");

    const { users } = useAppSelector((state: RootState) => state.users);

    useEffect(() => {
        dispatch(loadUsers());
    }, [dispatch]);

    const filteredUsers = React.useMemo(() => {
        if (search?.length < 3)
            return users;

        return users.filter((user: User) => {
            return user.fullname().match(new RegExp(search, 'gi'));
        });
    }, [users, search]);

    const usersByRole = {
        "adminUsers"    : filteredUsers?.filter((user: User) => user?.role === User.ROLE_ADMIN),
        "simpleUsers"   : filteredUsers?.filter((user: User) => user?.role === User.ROLE_USER),
        "externalUsers" : filteredUsers?.filter((user: User) => user?.role === User.ROLE_EXTERNAL),
        "clientUsers"   : filteredUsers?.filter((user: User) => user?.role === User.ROLE_CLIENT)
    }

    if (searchInput?.current)
        searchInput.current.focus();

    return (
        <Ability can="user:read" redirect="/">
            <div className="UsersList">
                <header className="bg-white border-b px-4 py-0 sm:px-6 lg:px-8">
                    <div className="my-2 mx-auto lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                <Icon type="user" size={7} className="mr-1.5 inline" />
                                { t('app.users') }
                            </h2>
                        </div>
                        <div className="mt-5 flex flex-col space-y-2 sm:space-y-0 sm:flex-row lg:ml-4 lg:mt-0">
                            <div className="relative flex">
                                <input
                                    type="text"
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                    ref={searchInput}
                                    className="w-full h-9 text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 rounded-md focus:ring-gray-300 border-0 focus:border-0 py-0 pr-8"
                                />
                                <Icon type="search" color="gray-600" className="absolute top-2.5 right-2.5" />
                            </div>
                            <span className="sm:ml-3">
                                <Button title={t('users.add_a_user')} color="navigate" to="/users/new" icon="plus" />
                            </span>
                        </div>
                    </div>
                </header>
                <main>
                    <div className="grid grid-cols-1 gap-4">
                        {Object.keys(usersByRole).filter((key: any) => usersByRole[key as keyof typeof usersByRole]?.length).map((key) => (
                        <div className="p-5" key={key}>
                            <Table striped hoverable>
                                <Table.Head>
                                    <Table.HeadCell colSpan={2}>{t("users."+key)}</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {(usersByRole[key as keyof typeof usersByRole]).sort((a: User, b: User) => a.firstname > b.firstname).map((user: User) => (
                                        <Table.Row key={user?._id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                <div className="flex flex-col gap-2 md:flex-row md:justify-between md:items-center">
                                                    <Link to={`/users/edit/${user._id}`} className="flex gap-x-3">
                                                        <UserAvatar user={user} hideInitials size={10} />
                                                        <div className="flex-row">
                                                            {user?.fullname()}
                                                            <div className="text-xs text-gray-500">{user.email}</div>
                                                        </div>
                                                    </Link>
                                                    <Button title={t('users.edit_this_user')} icon="edit" iconSize={4} color="primary" onClick={() => navigate(`/users/edit/${user._id}`)} small />
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </div>
                        ))}
                    </div>
                </main>
            </div>
        </Ability>
    );
}

export default UsersList;
