import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './redux';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import TimeAgo from 'javascript-time-ago';
import i18n from './locales/i18n';

import fr from 'javascript-time-ago/locale/fr.json';
import en from 'javascript-time-ago/locale/en.json';
import de from 'javascript-time-ago/locale/de.json';

import routes from './routes';
import infos from '../package.json';

const router = createBrowserRouter(routes, { basename: '/' });
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

TimeAgo.addDefaultLocale(fr);
TimeAgo.addLocale(en);
TimeAgo.addLocale(de);

if (i18n) {}

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://b325b9d9750a61bf5c5068089b04e7e0@sentry.neto2.net/4",
        integrations: [
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false
            })
        ],
        environment: process.env.NODE_ENV,
        release: infos.version,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.25,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // Learn more at
        // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

root.render(
/**
 * We should use <React.StrictMode> but it causes some issues
 * in react-beautiful-dnd component...
 */
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
