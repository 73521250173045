import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, Change } from '../../models';
import { Select, ChangeListItem, Icon } from '../../components';
import { RootState, useAppSelector, useAppDispatch, loadProjects, loadChanges } from '../../redux';
import type { Value } from '../../components/Select';

interface ChangePickerProps {
    filterProjects?: (projects: Project[]) => Project[];
    filterChanges?: (changes: Change[]) => Change[];
    onSelect?: (change: Change, e: AnyObj) => void;
    className?: string;
    selectClassName?: string;
    listClassName?: string;
    changeClassName?: string;
    defaultProjectId?: Value;
    acceptUnestimated?: boolean;
    acceptFinished?: boolean;
    hideLoader?: boolean;
}

function ChangePicker(props: ChangePickerProps) {
    const {
        filterProjects, filterChanges, onSelect, className, selectClassName, changeClassName,
        listClassName, defaultProjectId, acceptUnestimated, acceptFinished, hideLoader
    } = props;

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { projects }         = useAppSelector((state : RootState) => state.projects);
    const { changes, loading } = useAppSelector((state : RootState) => state.changes);

    const [projectId, setProjectId] = useState(defaultProjectId);

    useEffect(()=>{
        dispatch(loadProjects());
    },[dispatch]);

    useEffect(()=>{
        if (projectId) {
            const params: AnyObj = {
                project: projectId,
            };
            if (!acceptUnestimated)
                params.estimated = true;
            if (!acceptFinished)
                params.notfinished = true;
            dispatch(loadChanges(params));
        }
    },[dispatch, projectId]);

    const projectOptions = React.useMemo(() => {
        const allProjects = filterProjects ?  filterProjects(projects) : projects;

        return allProjects?.map((project: Project) => ({
            value: project._id,
            label: project.name
        }));
    }, [projects, filterProjects]);

    const availableChanges = React.useMemo(() => {
        let availableChanges = changes?.sort((a: Change, b: Change) => a.slugName() > b.slugName() ? 1 : -1);
        if (filterChanges)
            availableChanges = filterChanges(availableChanges);

        return availableChanges;
    }, [changes, filterChanges]);

    if (availableChanges?.length && !projectId)
        setProjectId(changes[0].project?._id);

    return (
        <div className={`flex flex-col space-y-2 w-full ${className || ''}`}>
            {!defaultProjectId && (
                <div>
                    <Select
                        defaultValue={projectId}
                        onChange={(projectId: Value) => setProjectId(projectId)}
                        placeholder={t('changes.choose_a_project') || ''}
                        options={projectOptions}
                        optionsHeight={'max-h-[300px]'}
                        optionsClassName={selectClassName || ''}
                    />
                </div>
            )}
            <div className={`flex flex-col space-y-2 ${listClassName || ''}`}>
                {!hideLoader && projectId && (changes?.length > 0) && !(availableChanges.length > 0) && (
                    <span className="text-sm h-8 font-light text-red-900 text-center">
                        {loading === 'pending' ? (
                            <Icon type="loading" className="inline" />
                        ) : (
                            <span>{t('changes.no_change_found_for_this_project')}</span>
                        )}
                    </span>
                )}
                {availableChanges.map((change: Change) => (
                    <ChangeListItem
                        key={change._id}
                        onSelect={onSelect}
                        change={change}
                        className={changeClassName || ''}
                    />
                ))}
            </div>
        </div>
    );
}

export default ChangePicker;
