import RestClient from './RestClient';
import { Project, Release, ProjectDocumentation } from '../models';
import { store } from '../redux';
import type { FetchOptions } from './Client';

class Projects extends RestClient {
    constructor() {
        super({
            // rest config
            model: Project,
            entryPoint: 'projects',
            sortBy: 'name',
            id_field: '_id',
            // redux config
            resource: 'project',
            resources: 'projects',
        });
    }

    getDocumentations(id: string) {
        let url = `/${this.entryPoint}/${id}/docs`;

        return this.GET(url, {})
            .then(response => response.json())
            .then(json => {
                if (json.documentations)
                    return { documentations: json.documentations.map((doc: AnyObj) => new ProjectDocumentation(doc))};

                throw new Error(json.flash.error);
        });
    }

    getChangelog(id: string, from?: string, merge?: string[]) {
        let url = `/${this.entryPoint}/${id}/changelog`;
        if (from) {
            url += `/${from}`;

            if (merge?.length)
                url += `/${merge.join(',')}`;
        }

        return this.GET(url, {})
            .then(response => response.json())
            .then(json => {
                if (json.project) {
                    json.project = new Project(json.project);

                    if (json.releases)
                        json.releases = json.releases.map((r: AnyObj) => new Release(r));
                    return { changelog: { ...json }};
                }

                throw new Error(json.flash.error);
            });
    }

    getDiff(id: string, from: string, to: string, file?: string) {
        const url = `/${this.entryPoint}/${id}/diff`;

        return this.POST(url, { from, to, file })
            .then(response => response.json())
            .then(json => {
                if (json.diff) {
                    return json;
                }

                throw new Error("Could not get diff");
            });
    }

    sync(id: string, forceRefresh?: boolean) {
        return this.POST(`/${this.entryPoint}/${id}/sync/${forceRefresh ? 'forceRefresh' : ''}`, {})
            .then(response => response.json())
            .then(json => {
                if (json.project) {
                    json.project = new Project(json.project);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    addMembers(id: string, members: string[]) {
        return this.POST(`/${this.entryPoint}/${id}/members`, members)
            .then(response => response.json())
            .then(json => {
                if (json.project) {
                    json.project = new Project(json.project);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    removeMembers(id: string, members: string[]) {
        return this.DELETE(`/${this.entryPoint}/${id}/members/${members.join(',')}`)
            .then(response => response.json())
            .then(json => {
                if (json.project) {
                    json.project = new Project(json.project);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    setAvatar(id: string, file: File) {
        const formData = new FormData();
        formData.append("file", file);

        const headers: AnyObj = {};
        const subscriberId = store.getState()?.mercure?.subscriberId;
        if (subscriberId)
            headers['X-MERCURE-SUBSCRIBER'] = subscriberId;

        let url = `${this.baseUrl}/${this.entryPoint}/${id}/avatar`;
        const options: FetchOptions = {
            method: 'POST',
            body: formData,
            headers,
            credentials: 'include',
        };

        return fetch(url, options)
            .then(response => response.json())
            .then(json => {
                if (json.project) {
                    json.project = new Project(json.project);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    unsetAvatar(id: string) {
        return this.DELETE(`/${this.entryPoint}/${id}/avatar`)
            .then(response => response.json())
            .then(json => {
                if (json.project) {
                    json.project = new Project(json.project);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const ProjectsApi = new Projects();

export default ProjectsApi;
