import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../../../../redux';
import { getChangelog, getDiff } from '../../../../redux';
import { Release } from '../../../../models';
import { Changelog, DiffView, Icon } from '../../../../components';

function PublicProjectView() {
    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const { id, from, merge, to, file }    = useParams();
    const { changelog, diff, gettingDiff } = useAppSelector((state: RootState) => state.projects);

    let path = file || '';
    try {
        path = atob(path);
    } catch (err) {
        path = file || '';
    }
    useEffect(() => {
        if (id && from && to)
            dispatch(getDiff(id, from, to, path));
        else if (id)
            dispatch(getChangelog(id, from, merge?.split(',')));
    }, [dispatch, id]);

    if (!changelog?.project && !diff?.from && gettingDiff !== 'pending')
        return null;

    const project = changelog?.project ?? diff?.project;

    const { releases, merged } = (changelog || {});

    return (
        <div className="ProjectView">
            <header className="bg-white border-b px-4 py-2 sm:px-6 lg:px-8">
                <div className="mx-auto lg:flex lg:itemProjects-center lg:justify-between">
                    <div className="flex flex-col space-y-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                            { project?.name }
                        </h2>
                        {merged?.length > 0 && (
                            <h3 className="">
                                <span className="text-md font-medium tracking-tight text-gray-900">&nbsp;
                                    {t('projects.includes')}&nbsp;
                                </span>
                                {merged?.map((mergedProject: AnyObj, i: number) => (
                                    <span className="text-md font-medium tracking-tight text-gray-899">
                                        {mergedProject.name}
                                        {i < merged.length - 2 ? ', ' : (i === merged.length - 2 ? ' ' + t('common.and') + ' ': '')}
                                    </span>
                                ))}
                            </h3>
                        )}
                    </div>
                </div>
            </header>
            <main className="mx-3">
                {(releases?.length > 0) && (
                    <div className="-mx-4 flex-1 shrink">
                        <Changelog
                            releases={releases.sort((a: Release, b: Release) => b.versionNumber - a.versionNumber)}
                        />
                    </div>
                )}

                {(gettingDiff === 'pending') && (
                    <div className="flex justify-center items-center m-3">
                        <Icon type="loading" size={ 20 } />
                    </div>
                )}

                {diff?.from && (
                    <div className="mb-5">
                        <div className="text-center text-lg">
                            <span className="font-bold">{ from }</span> -&gt; <span className="font-bold"> { to }</span>
                        </div>
                        <div className="text-center text-md">
                            <span className="font-light">{ path }</span>
                        </div>
                        {(diff.content?.length > 0) ? (
                            <DiffView diff={ diff.content } />
                        ) : (
                            <div className="flex justify-center items-center m-3">
                                <Icon type="sad" size={8} />
                            </div>
                        )}
                    </div>
                )}
            </main>
        </div>
    );
}

export default PublicProjectView;
