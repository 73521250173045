import BaseModel from './BaseModel';
import { i18n } from '../locales';
import Project from './Project';
import Change from './Change';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

export interface ColumnData {
    _id: string;
    title: string;
    changes: Change[];
    isSmart: boolean;
    rules: {
        hideIfEmpty: boolean;
        filters: [{
            property: string;
            op: string;
            values: string[];
        }],
        sort: [{
            property: string;
            order: number;
        }],
        limit: number
    },
    editable: boolean;
    acceptNewCards: boolean;
}

export interface BoardFilters {
    user: User | undefined;
    search: string | undefined;
}

/** Class representing a board. */
class Board extends BaseModel {
    _id: string;
    name: Record<string, string>;
    createdAt: string;
    updatedAt: string;
    project: Project;
    columns: ColumnData[];
    acceptNewColumns: boolean;
    icon: string;

    constructor(properties: AnyObj) {
        super(properties);

        if (_.isObject(this.project))
            this.project = new Project(this.project);

        this.columns = (this.columns || []).map((column: ColumnData) => {
            column.changes = (column.changes || []).map((change: AnyObj) => {
                if (_.isObject(change))
                    change = new Change(change);
                return change;
            });

            if (!('editable' in column))
                column.editable       = true;
            if (!('acceptNewCards' in column))
                column.acceptNewCards = !column.isSmart;

            return column;
        });
        this.acceptNewColumns = !('acceptNewColumns' in this) || this.acceptNewColumns;
        this.icon = this.icon ?? 'board';
    }

    filterOn(filters: BoardFilters) {
        if (!filters)
            return this;

        const { user, search } = filters;
        if (!user && !search)
            return this;

        const board = new Board(_.cloneDeep(this));
        board.columns = board.columns.map((column: ColumnData) => {
            column.changes = column.changes.filter((change: Change) => {
                if (search && !change.match(search))
                    return false;

                // filter on assignee
                if (user && user?._id !== change.assignee?._id)
                    return false;

                return true;
            });

            return column;
        });

        return board;
    }

    /**
     * Get an object containing all the predefined boards
     *
     * @param {Project} project
     * @return {Object}
     */
    static predefinedBoards(project: Project): Record<string, Board> {
        return {
            progression: Board.progressionBoard(project),
            resouces: Board.resourcesBoard(project),
            types: Board.typesBoard(project),
            priority: Board.priorityBoard(project),
            moscow: Board.moscowBoard(project),
        };
    }

    /**
     * Get a a board representing the project tasks progression.
     * One column per progression step.
     *
     * @param {Project} project
     * @return {Board}
     */
    static progressionBoard(project: Project): Board {
        const columns = {
            'À estimer': [Change.STATUS_PENDING_ESTIMATE],
            'À planifier': [Change.STATUS_ESTIMATED, Change.STATUS_PENDING_PLANIFICATION],
            'À assigner': [Change.STATUS_PLANNED],
            'À commencer': [Change.STATUS_ASSIGNED],
            'À terminer': [Change.STATUS_IN_PROGRESS],
            'À review': [Change.STATUS_TEAM_REVIEW],
            'En test': [Change.STATUS_CLIENT_REVIEW],
            'À déployer': [Change.STATUS_PENDING_DEPLOYMENT],
            'Terminé (récemment)': [Change.STATUS_DEPLOYED, Change.STATUS_FINISHED]
        };
        return new Board({
            _id: uuidv4(),
            project: project._id,
            name: {
                fr: i18n.t('projects.board_progression', { lng: 'fr' }),
                en: i18n.t('projects.board_progression', { lng: 'en' }),
                de: i18n.t('projects.board_progression', { lng: 'de' }),
            },
            icon: 'progression',
            columns: Object.keys(columns).map((title: string, index: number) => ({
                _id: uuidv4(),
                title,
                rules: {
                    filters: [
                        { property: 'project', op: '$eq', values: [ project._id ] },
                        { property: 'progress.status', op: '$in', values: columns[title] }
                    ],
                    sort: [{ property: 'slug', order: -1 }],
                    limit: index === columns.length - 1 ? 20 : 50
                },
                isSmart: true
            }))
        });
    }

    /**
     * Get a a board representing the project resources alocation.
     * One column per project member.
     *
     * @param {Project} project
     * @return {Board}
     */
    static resourcesBoard(project: Project): Board {
        return new Board({
            _id: uuidv4(),
            project: project._id,
            name: {
                fr: i18n.t('projects.board_resources', { lng: 'fr' }),
                en: i18n.t('projects.board_resources', { lng: 'en' }),
                de: i18n.t('projects.board_resources', { lng: 'de' }),
            },
            icon: 'user',
            columns: project.members?.sort((a: User, b: User) => a.fullname().localeCompare(b.fullname())).map((user: User) => ({
                _id: uuidv4(),
                title: user.fullname(),
                rules: {
                    hideIfEmpty: true,
                    filters: [
                        { property: 'project', op: '$eq', values: [ project._id ] },
                        { property: 'assignee', op: '$eq', values: [ user._id ] },
                        { property: 'progress.status', op: '!$in', values: [
                            Change.STATUS_PENDING_DEPLOYMENT,
                            Change.STATUS_DEPLOYED,
                            Change.STATUS_FINISHED
                        ]}
                    ],
                    sort: [{ property: 'slug', order: -1 }],
                    limit: 100
                },
                isSmart: true
            }))
        });
    }

    /**
     * Get a a board representing the tasks types repartition.
     * One column per task type.
     *
     * @param {Project} project
     * @return {Board}
     */
    static typesBoard(project: Project): Board {
        const types = {
            [i18n.t('projects.feature_plural')]: [Change.TYPE_FEATURE],
            [i18n.t('projects.fix_plural')]: [Change.TYPE_FIX],
            [i18n.t('projects.refactor_plural')]: [Change.TYPE_REFACTOR],
            [i18n.t('projects.ui_plural')]: [Change.TYPE_UI],
            [i18n.t('projects.i18n_plural')]: [Change.TYPE_I18N],
            [i18n.t('projects.test_plural')]: [Change.TYPE_TEST],
            [i18n.t('projects.doc_plural')]: [Change.TYPE_DOC],
            [i18n.t('projects.lint_plural')]: [Change.TYPE_LINT],
            [i18n.t('projects.chore_plural')]: [Change.TYPE_CHORE],
            [i18n.t('projects.build_plural')]: [Change.TYPE_BUILD],
            [i18n.t('projects.dependencies_plural')]: [Change.TYPE_DEPS],
            [i18n.t('projects.ignored_plural')]: [Change.TYPE_IGNORED],
            [i18n.t('projects.none_plural')]: [Change.TYPE_NONE],
        };
        return new Board({
            _id: uuidv4(),
            project: project._id,
            name: {
                fr: i18n.t('projects.board_types', { lng: 'fr' }),
                en: i18n.t('projects.board_types', { lng: 'en' }),
                de: i18n.t('projects.board_types', { lng: 'de' }),
            },
            icon: 'commit',
            columns: Object.keys(types).map((title: string, index: number) => ({
                _id: uuidv4(),
                title,
                rules: {
                    hideIfEmpty: true,
                    filters: [
                        { property: 'project', op: '$eq', values: [ project._id ] },
                        { property: 'type', op: '$in', values: types[title] },
                        { property: 'progress.status', op: '!$in', values: [
                            Change.STATUS_PENDING_DEPLOYMENT,
                            Change.STATUS_DEPLOYED,
                            Change.STATUS_FINISHED
                        ]}
                    ],
                    sort: [{ property: 'slug', order: -1 }],
                    limit: 100
                },
                isSmart: true
            }))
        });
    }

    /**
     * Get a a board representing the MoSCoW matrix representation.
     * One column for each of the four following categories:
     * - Must have
     * - Should have
     * - Could have
     * - Won't have
     *
     * @param {Project} project
     * @return {Board}
     */
    static moscowBoard(project: Project): Board {
        const categories = {
            'Won\'t have': [0],
            'Could have': [1, 2],
            'Should have': [3, 4],
            'Must have': [5],
        };
        return new Board({
            _id: uuidv4(),
            project: project._id,
            name: {
                fr: i18n.t('projects.board_moscow', { lng: 'fr' }),
                en: i18n.t('projects.board_moscow', { lng: 'en' }),
                de: i18n.t('projects.board_moscow', { lng: 'de' }),
            },
            icon: 'importance',
            columns: Object.keys(categories).map((title: string, index: number) => ({
                _id: uuidv4(),
                title,
                rules: {
                    hideIfEmpty: false,
                    filters: [
                        { property: 'project', op: '$eq', values: [ project._id ] },
                        { property: 'importance', op: '$in', values: categories[title] },
                        { property: 'progress.status', op: '!$in', values: [
                            Change.STATUS_PENDING_DEPLOYMENT,
                            Change.STATUS_DEPLOYED,
                            Change.STATUS_FINISHED
                        ]}
                    ],
                    sort: [{ property: 'priority', order: -1 }],
                    limit: 100
                },
                isSmart: true
            }))
        });
    }

    /**
     * Get a a board representing the tasks priority.
     * One column for each of the five following priority ranges:
     * - Very high: [101 - 125]
     * - High: [76 - 100]
     * - Normal: [51 - 75]
     * - Low: [26 - 50]
     * - Very low: [0 - 25]
     *
     * @param {Project} project
     * @return {Board}
     */
    static priorityBoard(project: Project): Board {
        const categories = {
            [i18n.t('changes.priority_very_low')]: [0, 25],
            [i18n.t('changes.priority_low')]: [26, 50],
            [i18n.t('changes.priority_normal')]: [51, 75],
            [i18n.t('changes.priority_high')]: [76, 100],
            [i18n.t('changes.priority_very_high')]: [101, 125],
        };
        return new Board({
            _id: uuidv4(),
            project: project._id,
            name: {
                fr: i18n.t('projects.board_priority', { lng: 'fr' }),
                en: i18n.t('projects.board_priority', { lng: 'en' }),
                de: i18n.t('projects.board_priority', { lng: 'de' }),
            },
            icon: 'priority',
            columns: Object.keys(categories).map((title: string, index: number) => ({
                _id: uuidv4(),
                title,
                rules: {
                    hideIfEmpty: false,
                    filters: [
                        { property: 'project', op: '$eq', values: [ project._id ] },
                        { property: 'priority', op: '$gte', values: categories[title][0] },
                        { property: 'priority', op: '$lte', values: categories[title][1] },
                        { property: 'progress.status', op: '!$in', values: [
                            Change.STATUS_PENDING_DEPLOYMENT,
                            Change.STATUS_DEPLOYED,
                            Change.STATUS_FINISHED
                        ]}
                    ],
                    sort: [{ property: 'priority', order: -1 }],
                    limit: 100
                },
                isSmart: true
            }))
        });
    }
}

export default Board;
