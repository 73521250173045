import {
    App,
    Login,
    Public,
    Dashboard,
    Projects, ProjectCreate, ProjectEdit, ProjectView, ProjectDelete, ProjectsList,
    Goals, GoalsList, GoalCreate, GoalEdit, GoalView, GoalDelete,
    Sprints, SprintsList, SprintCreate, SprintEdit, SprintView, SprintDelete, SprintMergeRequests,
    Quotes, QuotesList, QuoteCreate, QuoteEdit, QuoteView, QuoteDelete,
    PublicProjectView, PublicProjectReportView,
    Users, UserCreate, UserEdit, UsersList, UserDelete, UserTracks,
    Settings, SettingEdit,
    DocumentationsList
} from './containers';

const routes = [
    {
        path: "public",
        element: <Public />,
        children: [
            {
                path: "projects",
                element: <Projects />,
                children: [
                    { path: ":id/changelog", element: <PublicProjectView /> },
                    { path: ":id/changelog/from/:from", element: <PublicProjectView /> },
                    { path: ":id/changelog/from/:from/merge/:merge", element: <PublicProjectView /> },
                    { path: ":id/diff/from/:from/to/:to", element: <PublicProjectView /> },
                    { path: ":id/diff/from/:from/to/:to/file/:file", element: <PublicProjectView /> },
                ]
            },
            {
                path: "support/project/:projectId/source/:source",
                element: <PublicProjectReportView />,
            },
        ]
    },
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "/",
        element: <App />,
        children: [
            { path: "", element: <Dashboard /> },
            { path: "projects", element: <Projects />, children: [
                { path: "", element: <ProjectsList /> },
                { path: "new", element: <ProjectCreate /> },
                { path: ":id", element: <ProjectView /> },
                { path: ":id/release/:releaseId", element: <ProjectView /> },
                { path: ":id/change/:changeId", element: <ProjectView /> },
                { path: "edit/:id", element: <ProjectEdit /> },
                { path: "delete/:id", element: <ProjectDelete /> },
            ]},
            { path: "goals", element: <Goals />, children: [
                { path: "", element: <GoalsList /> },
                { path: "new", element: <GoalCreate /> },
                { path: ":id", element: <GoalView /> },
                { path: "edit/:id", element: <GoalEdit /> },
                { path: "delete/:id", element: <GoalDelete /> },
            ]},
            { path: "sprints", element: <Sprints />, children: [
                { path: "", element: <SprintsList /> },
                { path: "new", element: <SprintCreate /> },
                { path: ":id", element: <SprintView /> },
                { path: "edit/:id", element: <SprintEdit /> },
                { path: "delete/:id", element: <SprintDelete /> },
                { path: "mergerequests", element: <SprintMergeRequests /> },
            ]},
            { path: "quotes", element: <Quotes />, children: [
                { path: "", element: <QuotesList /> },
                { path: "new", element: <QuoteCreate /> },
                { path: ":id", element: <QuoteView /> },
                { path: "edit/:id", element: <QuoteEdit /> },
                { path: "delete/:id", element: <QuoteDelete /> },
            ]},
            { path: "users", element: <Users />, children: [
                { path: "", element: <UsersList /> },
                { path: "new", element: <UserCreate /> },
                { path: "edit/:id", element: <UserEdit /> },
                { path: "delete/:id", element: <UserDelete /> },
                { path: "tracks", element: <UserTracks /> },
            ]},
            { path: "settings", element: <Settings />, children: [
                { path: "edit/:id", element: <SettingEdit /> }
            ]},
            { path: "documentations", element: <DocumentationsList />, children: [
                { path: "", element: <DocumentationsList /> }
            ]},
        ]
    }
];

export default routes;
