import React from 'react';
import { useTranslation } from 'react-i18next';
import { Change } from '../../models';
import { Icon, UserAvatar, Tooltip } from '../../components';
import { Badge, Progress } from 'flowbite-react';

interface ChangeListItemProps {
    change: Change;
    onSelect?: (change: Change, e: AnyObj) => void;
    onRemove?: (change: Change, e: AnyObj) => void;
    showProject?: boolean;
    showProgression?: boolean;
    className?: string;
    tabIndex?: number;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

function ChangeListItem(props: ChangeListItemProps) {
    const { change, onSelect, onRemove, showProject, showProgression, className } = props;

    const { t, i18n } = useTranslation();

    const progression = Math.round(change.progression());

    const content = React.useMemo(() => (
        <div
            className={`overflow-hidden min-h-[60px] border rounded p-2 cursor-pointer ${className ? className : ""} ${props.tabIndex === 0 ? "bg-blue-100" : ""}`}
            tabIndex={props.tabIndex}
            style={{outline: "none"}}
            onClick={props.onClick}
        >
            <div className={`flex flex-row items-center space-x-2`}>
                <div className="flex-col space-y-0.5">
                    <Badge color={change.color()} className="w-[70px] flex-none text-xs">
                        <Icon type={change.iconName()} size={3} className="inline"/>&nbsp;
                        {change.slugName()}
                    </Badge>
                    <Badge color="warning" className="w-[70px] flex-none whitespace-nowrap">
                        <>
                            <Icon type="estimated" size={3} className="inline mr-1"/>&nbsp;
                            {change.estimate} {t('changes.estimate_units_min')}
                        </>
                    </Badge>
                </div>
                <div className="flex-1">
                    <h4 className="text-sm font-light heading-md">
                        {change.localizedTitle(i18n.language)}
                    </h4>
                    {showProject && (
                        <h4 className="text-xs font-bold heading-md">
                            {change.project?.name}
                        </h4>
                    )}
                </div>
                {change.isFinished() && (
                    <Icon type="check" color="green-600" size={6} />
                )}
                {change.isInReview() && (
                    <Icon type="eye" color="blue-600" size={6} />
                )}
                <Badge color="indigo" className="w-fit" size="xs">
                    <Icon type="priority" className="inline mr-1" />
                    {change.priority}
                </Badge>
                {change.assignee && change.assignee._id && (
                    <UserAvatar user={change.assignee} size={6} />
                )}
                {onSelect && (
                    <Badge
                        color="success"
                        className="text-xs"
                        onClick={(e: AnyObj) => {
                            e.stopPropagation();
                            onSelect(change, e);
                        }}
                    >
                        <Icon type="right" className="" />
                    </Badge>
                )}
                {onRemove && (
                    <Badge
                        color="failure"
                        className="text-xs"
                        onClick={(e: AnyObj) => {
                            e.stopPropagation();
                            onRemove(change, e);
                        }}
                    >
                        <Icon type="close" color="red-900" className="" />
                    </Badge>
                )}
            </div>
            {showProgression && (
                <Tooltip content={progression + '%'} placement="top">
                    <div className="mt-1">
                        <Progress
                            progress={progression}
                            size="sm"
                            color={ progression === 100 ? 'green' : (progression > 20 ? 'blue' : 'yellow')}
                        />
                    </div>
                </Tooltip>
            )}
        </div>
    ), [ change?.updatedAt ]);

    return content;
}

export default ChangeListItem;
