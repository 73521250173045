import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'flowbite-react';
import { Project } from '../../models';
import { Icon } from '..';
import ReactTimeAgo from 'react-time-ago';
import moment from 'moment';

interface ProjectChecksProps {
    project: Project;
}

function ProjectChecks(props: ProjectChecksProps) {
    const { project } = props;

    const [checksType, setChecksType] = useState('dependencies');

    const { t, i18n } = useTranslation();

    const { dependencies } = (project?.checks || {});

    const today = moment();

    const packages = React.useMemo(() => {
        if (!dependencies?.packages?.length)
            return null;

        const majors = dependencies.packages.filter((p: any) => p.status === 'MAJOR');
        const minors = dependencies.packages.filter((p: any) => p.status === 'MINOR');
        const patchs = dependencies.packages.filter((p: any) => p.status === 'PATCH');

        const packages: any = [];

        if (majors?.length > 0) {
            packages.push(<h2 className="font-bold text-xl mt-2 mb-1">{t('projects.majors')} ({majors.length})</h2>);
            majors?.forEach((p: any) => {
                packages.push((
                    <div className="flex flex-col sm:flex-row justify-between border rounded mb-1">
                        <div className="grow font-normal p-2">{ p.package }</div>
                        <div className="flex-none w-full sm:w-64 font-light p-2 bg-red-900 text-white rounded-b sm:rounded-l-none sm:rounded-r text-center">
                            { p.installed } {"=>"} { p.latest }
                        </div>
                    </div>
                ));
            });
        }

        if (minors?.length > 0) {
            packages.push(<h2 className="font-bold text-xl mt-2 mb-1">{t('projects.minors')} ({minors.length})</h2>);
            minors?.forEach((p: any) => {
                packages.push((
                    <div className="flex flex-col sm:flex-row justify-between border rounded mb-1">
                        <div className="grow font-normal p-2">{ p.package }</div>
                        <div className="flex-none w-full sm:w-64 font-light p-2 bg-orange-700 text-white rounded-b sm:rounded-l-none sm:rounded-r text-center">
                            { p.installed } {"=>"} { p.latest }
                        </div>
                    </div>
                ));
            });
        }

        if (patchs?.length > 0) {
            packages.push(<h2 className="font-bold text-xl mt-2 mb-1">{t('projects.patchs')} ({patchs.length})</h2>);
            patchs?.forEach((p: any) => {
                packages.push((
                    <div className="flex flex-col sm:flex-row justify-between border rounded mb-1">
                        <div className="grow font-normal p-2">{ p.package }</div>
                        <div className="flex-none w-full sm:w-64 font-light p-2 bg-yellow-700 text-white rounded-b sm:rounded-l-none sm:rounded-r text-center">
                            { p.installed } {"=>"} { p.latest }
                        </div>
                    </div>
                ));
            });
        }

        return packages;
    }, [ dependencies ]);

    return (
        <Card className="ProjectChecks">
            <h2 className="text-2xl font-bold leading-7 flex items-center text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                <Icon type="check" size={7} className="mr-1.5" />
                {t('projects.checks')}
            </h2>

            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-full md:col-span-3">
                    <div
                        className={`cursor-pointer w-full border ${checksType === 'dependencies' ? "bg-blue-100 hover:bg-blue-200" : "bg-gray-100 hover:bg-gray-200"} p-2 my-1 rounded-md`}
                        onClick={() => setChecksType('dependencies')}
                    >
                        { t('projects.checks_types.dependencies') }
                            {dependencies?.date && (
                                <div className='text-xs'>
                                    { t('projects.checks_done_on') }&nbsp;
                                    { moment.duration(today.diff(dependencies.date)).asHours() < 48 ? (
                                       <ReactTimeAgo locale={i18n.language} date={dependencies.date.toDate()}/>
                                    ) : (
                                        `Le ${dependencies.date.format('ll')}`
                                    )}
                                </div>
                            )}
                    </div>
                </div>
                <div className="col-span-full md:col-span-9">
                    { packages }

                    { !project?.hasDependeciesCheckResult() && (
                        <div className="flex flex-col items-center">
                            <Icon type="sad" size={ 8 }/>
                            <h3 className="italic">
                                { t('projects.no_dependencies_checks') }
                            </h3>
                        </div>
                    )}
                </div>
            </div>

        </Card>
    );
}

export default ProjectChecks;
