import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Icon, Button, LangField, NotificationEventsField,
    NotificationGroupsField, UserSelectField, SelectField,
    ClientSelectField, ImageUploader, RoutinesManager,
    MultiSelectField
} from '../../../components';
import type { ImageListType, IconType } from '../../../components';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Avatar } from 'flowbite-react';
import type { FormikValues } from 'formik';
import { RootState, useAppDispatch, useAppSelector, setProjectAvatar, unsetProjectAvatar, loadSentryProjects } from '../../../redux';
import { Project, Change, ProjectDocumentation, SentryProject } from '../../../models';

interface ProjectFormProps {
    isCreation: boolean,
    initialValues: FormikValues,
    onSubmit: (values: AnyObj) => void
}

function ProjectForm(props: ProjectFormProps) {
    const { isCreation, initialValues, onSubmit } = props;

    const { t }              = useTranslation();
    const dispatch           = useAppDispatch();
    const { setting }        = useAppSelector((state: RootState) => state.settings);
    const { updating }       = useAppSelector((state: RootState) => state.projects);
    const { sentryProjects } = useAppSelector((state: RootState) => state.sentry);

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (setting?.sentry.enabled)
            dispatch(loadSentryProjects());
    }, [dispatch, setting]);

    const validate = (values: AnyObj) => {
        return {};
    }

    const formatOptions = ProjectDocumentation.formatTypes().map((type: string) => ({
        value: type,
        label: t(`projects.documentation_format_types.${type}`)
    }));

    const sourceOptions = ProjectDocumentation.sourceTypes().map((type: string) => ({
        value: type,
        label: t(`projects.documentation_source_types.${type}`),
        disabled : type === 'local'
    }));

    const onAvatarChange = (imageList: ImageListType) => {
        if (imageList.length > 0) {
            const file: File | undefined = imageList[0].file;
            if (file)
                return dispatch(setProjectAvatar(initialValues._id, file));
        }
    };

    const removeAvatar = () => {
        return dispatch(unsetProjectAvatar(initialValues._id));
    }

    const project = new Project(initialValues);

    const tabs = [
        ['general', 'info'],
        ['repository', 'branch'],
        ['translations', 'notes'],
        ['documentation', 'comment'],
        ['notifications', 'email'],
        ['routines', 'routine'],
        ['commitRules', 'commit'],
        ['tagRules', 'release'],
    ];
    if (setting?.sentry.enabled)
        tabs.push(['sentry', 'bug']);

    // let's hide the bottom buttons on the Routines tab
    const mustHideBottomButtons = activeTab === 5;

    const sentryProjectsOptionsIds = sentryProjects?.map((p: SentryProject) => ({
        value: p.id,
        label: p.name
    }));

    const sentryProjectsOptionsSlugs = sentryProjects?.map((p: SentryProject) => ({
        value: p.slug,
        label: p.name
    }));

    const sentryPriorities = Change.getIssuesPrioritiesValues().map((p: string) => ({
        value: p,
        label: t(`projects.issuesPriorities.${p}`) || ''
    }));

    return (
        <div className="ProjectForm bg-white border-b p-4 sm:p-6">
            <main className='md:flex text-center md:text-left'>
                <div id="default-sidebar" className="z-40 md:w-64">
                    <div className="p-0 bg-gray-50 dark:bg-gray-800 border border-gray-100 rounded">
                        {!isCreation && (
                            <div className="flex justify-center">
                                <ImageUploader hidePreview onChange={onAvatarChange} className="!border-0">
                                    <div className="group relative">
                                        <Avatar img={project.getAvatarUrl(80, true)} bordered size="lg" />
                                        {project.avatar && (
                                            <Button
                                                title=""
                                                icon="delete"
                                                onClick={removeAvatar}
                                                iconColor="red-600"
                                                className="hidden group-hover:block absolute -bottom-3 -right-5"
                                                color="none"
                                            />
                                        )}
                                    </div>
                                </ImageUploader>
                            </div>
                        )}
                        <ul className="space-y-0 font-medium text-gray-600 border-t">
                            {tabs.map(([label, icon], i: number) => (
                                <li key={label} className={`cursor-pointer w-full text-sm py-3 ${(activeTab === i) ? "text-gray-900 bg-gray-200" : "hover:bg-gray-100" }`} onClick={() => setActiveTab(i)}>
                                    <p className="ms-3 flex items-center">
                                        <Icon color={activeTab === i ? 'gray-900' : 'gray-500'} type={icon as IconType} className="inline mr-1" />
                                        {t(`projects.${label}`)}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='w-full px-4 py-4'>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={(values, { setSubmitting }) => {
                            onSubmit(values);
                        }}
                    >
                        {({ values }) => (
                            <Form>
                                <div className="space-y-12">
                                    <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 space-x-0 lg:space-x-12">
                                        {activeTab === 0 &&
                                        <div className="flex-1">
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                                <div className="col-span-full">
                                                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('projects.name')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name="name"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-full">
                                                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('projects.description')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            as="textarea"
                                                            name="description"
                                                            rows={3}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-full">
                                                    <label htmlFor="clients" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('projects.clients')}
                                                    </label>
                                                    <div className="mt-2 flex flex-row">
                                                        <ClientSelectField name="clients" multiple placeholder={t('projects.select_clients') || ''} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {activeTab === 1 &&
                                        <div className="flex-1">
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                                <div className="md:col-span-4">
                                                    <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('projects.url')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name="repository.url"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="https://gitlab.netoxygen.ch/project/repo.git"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <label htmlFor="branch" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('projects.branch')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name="repository.branch"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <h2 className="mt-4 mb-1 text-base font-semibold leadin text-sm text-gray-900">{t('projects.repository_auth')}</h2>
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                                <div className="md:col-span-4">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('projects.username')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name="repository.username"
                                                            autoComplete="off"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="yoda"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('projects.password')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="password"
                                                            name="repository.password"
                                                            autoComplete="new-password"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {activeTab === 2 &&
                                        <div className="flex-1">
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                                <div className="col-span-3">
                                                    <label htmlFor="translation.languages.all" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('projects.translation.languages.all')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <LangField
                                                            name="translation.languages.all"
                                                            multiple
                                                            placeholder={t('projects.choose_some_languages') || ''}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-3">
                                                    <label htmlFor="translation.languages.default" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('projects.translation.languages.default')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <LangField
                                                            name="translation.languages.default"
                                                            placeholder={t('projects.choose_a_language') || ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mt-3">
                                                <div className="col-span-6">
                                                    <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t('projects.translation.context')}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name="translation.context"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder={t('projects.translation.context_placeholder')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {activeTab === 3 &&
                                        <div className="flex-1">
                                            <div className="col-span-1">
                                                <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                    {t('projects.readme_path')}
                                                </label>
                                                <div className="mt-2">
                                                    <Field
                                                        type="text"
                                                        name="readme.path"
                                                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full"
                                                        placeholder={t('projects.readme_path_placeholder')}
                                                    />
                                                </div>
                                            </div>
                                            <FieldArray name="documentations">
                                                {({ insert, remove, push }) => (
                                                    <div>
                                                        {values.documentations?.length > 0 && values.documentations.map((documentation: AnyObj, i: number) => (
                                                            <div key={i} className="flex flex-col border my-2 rounded hover:bg-gray-50">
                                                                <div key={i} className="grid grid-cols-4 gap-x-6 gap-y-4 p-3">
                                                                    <div className='col-span-4 md:col-span-2 lg:col-span-1'>
                                                                        <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                                            {t(`projects.documentation_name`)}
                                                                        </label>
                                                                        <div className="mt-2 pb-2">
                                                                            <Field
                                                                                type="text"
                                                                                name={`documentations[${i}].name`}
                                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                                placeholder={t('projects.documentation_name_placeholder')}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-span-4 md:col-span-2 lg:col-span-1'>
                                                                        <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                                            {t(`projects.documentation_source`)}
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <SelectField
                                                                                name={`documentations[${i}].source`}
                                                                                options={sourceOptions}
                                                                                placeholder={t('projects.choose_documentation_source') || ''}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-span-4 md:col-span-2 lg:col-span-1'>
                                                                        <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                                            {t(`projects.documentation_path`)}
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <Field
                                                                                type="text"
                                                                                name={`documentations[${i}].path`}
                                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                                placeholder={t('projects.documentation_path_placeholder')}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-span-4 md:col-span-2 lg:col-span-1'>
                                                                        <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                                            {t(`projects.documentation_format`)}
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <SelectField
                                                                                name={`documentations[${i}].format`}
                                                                                options={formatOptions}
                                                                                placeholder={t('projects.choose_documentation_format') || ''}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="cursor-pointer flex justify-center items-centeri mb-2">
                                                                    <Button
                                                                        onClick={() => remove(i)}
                                                                        title={t('projects.remove_this_documentation')}
                                                                        color="danger"
                                                                        icon="delete"
                                                                        small
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="flex justify-center">
                                                            <Button
                                                                onClick={() => push({})}
                                                                title={t('projects.add_documentation')}
                                                                color="navigate"
                                                                icon="plus"
                                                                small
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>
                                        }
                                        {activeTab === 4 &&
                                        <div className="flex-1">
                                            <p className="mt-1 text-sm leading-6 font-normal text-gray-600">
                                                {t('projects.notificationsDesc')}
                                            </p>
                                            <FieldArray name="notifications">
                                                {({ insert, remove, push }) => (
                                                    <div>
                                                        {values.notifications.length > 0 && values.notifications.map((notification: AnyObj, i: number) => (
                                                            <div key={i} className="flex flex-col border my-2 rounded hover:bg-gray-50">
                                                                <div key={i} className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-6 p-3">
                                                                    <div className="col-span-2">
                                                                        <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                                            {t(`projects.notifications_events`)}
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <NotificationEventsField
                                                                                name={`notifications[${i}].events`}
                                                                                placeholder={t('projects.choose_notification_events') || ''}
                                                                                multiple
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-span-2">
                                                                        <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                                            {t(`projects.notifications_groups`)}
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <NotificationGroupsField
                                                                                name={`notifications[${i}].groups`}
                                                                                placeholder={t('projects.choose_notification_groups') || ''}
                                                                                multiple
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-span-2">
                                                                        <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                                            {t(`projects.notifications_users`)}
                                                                        </label>
                                                                        <div className="mt-2">
                                                                            <UserSelectField
                                                                                name={`notifications[${i}].users`}
                                                                                placeholder={t('projects.choose_notification_users') || ''}
                                                                                multiple
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="cursor-pointer flex justify-center items-centeri mb-2">
                                                                    <Button
                                                                        onClick={() => remove(i)}
                                                                        title={t('projects.remove_this_notification_rule')}
                                                                        color="danger"
                                                                        icon="delete"
                                                                        small
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="flex justify-center">
                                                            <Button
                                                                onClick={() => push({})}
                                                                title={t('projects.add_notification_rule')}
                                                                color="navigate"
                                                                icon="plus"
                                                                small
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>
                                        }
                                        {activeTab === 5 &&
                                        <div className="flex-1">
                                            <p className="mb-2 text-sm leading-6 font-normal text-gray-600">
                                                {t('projects.routinesDesc')}
                                            </p>
                                            <RoutinesManager projectId={values._id} />
                                        </div>
                                        }
                                        {activeTab === 6 &&
                                        <div className="flex-1">
                                            <p className="mt-1 text-sm leading-6 font-normal text-gray-600">
                                                {t('projects.commitRulesDesc')}
                                            </p>
                                            <div className="grid grid-cols-3 gap-x-6 gap-y-4 sm:grid-cols-6">
                                                {['feature', 'fix', 'release', 'i18n', 'ui', 'refactor', 'test', 'doc', 'lint', 'build', 'dependencies', 'chore'].map(key => (
                                                <div key={key} className="col-span-2">
                                                    <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t(`projects.${key}`)}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name={`commitRules.${key}`}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                        }
                                        {activeTab === 7 &&
                                        <div className="flex-1">
                                            <p className="mt-1 text-sm leading-6 font-normal text-gray-600">
                                                {t('projects.tagRulesDesc')}
                                            </p>
                                            <div className="grid grid-cols-3 gap-x-6 gap-y-4 sm:grid-cols-6">
                                                {['releaseTag'].map(key => (
                                                <div key={key} className="col-span-6">
                                                    <label htmlFor="url" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t(`projects.${key}`)}
                                                    </label>
                                                    <div className="mt-2">
                                                        <Field
                                                            type="text"
                                                            name={`tagRules.${key}`}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                        }
                                        {activeTab === 8 &&
                                        <div className="flex-1">
                                            <p className="mt-1 text-sm leading-6 font-normal text-gray-600">
                                                {t('projects.sentryDesc')}
                                            </p>
                                            <div className="grid grid-cols-6 gap-x-6 gap-y-4 lg:grid-cols-12">
                                                <div className="col-span-6 mt-2">
                                                    <label htmlFor="sentry.projects" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t(`projects.issuesSources`)}
                                                    </label>
                                                    <div>
                                                        <small>{t(`projects.issuesSourcesDesc`)}</small>
                                                        <MultiSelectField name="sentry.issues.sources" options={sentryProjectsOptionsIds}
                                                            placeholder={t('projects.choose_some_sentry_projects') || ''} />
                                                    </div>
                                                </div>
                                                <div className="col-span-6 mt-2">
                                                    <label htmlFor="sentry.priority" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t(`projects.issuesPriority`)}
                                                    </label>
                                                    <div>
                                                        <small>{t(`projects.issuesPriorityDesc`)}</small>
                                                        <SelectField name="sentry.issues.priority" options={sentryPriorities}
                                                            placeholder={t('projects.choose_an_issue_priority') || ''} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 gap-x-6 gap-y-4 sm:grid-cols-6">
                                                <div className="col-span-12 mt-4">
                                                    <label htmlFor="sentry.projects" className="block text-sm font-medium leading-6 text-gray-900">
                                                        {t(`projects.releasesTargets`)}
                                                    </label>
                                                    <div>
                                                        <small>{t(`projects.releasesTargetsDesc`)}</small>
                                                        <MultiSelectField name="sentry.releases.targets" options={sentryProjectsOptionsSlugs}
                                                            placeholder={t('projects.choose_some_sentry_projects') || ''} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>

                                {!mustHideBottomButtons && (
                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <Button to="/projects" title={t('common.cancel')} color="navigateBack" icon="back" />
                                        <Button type="submit" title={t('common.save')} color="primary" icon={updating === 'pending' ? 'loading' : 'save'} />
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
            </main>
        </div>
    );

}

export default ProjectForm;
