import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import type { FormikValues } from 'formik';
import { useNavigate } from "react-router-dom";
import {RootState, useAppSelector, useAppDispatch, updateQuote} from '../../../redux';
import { loadProjects } from '../../../redux';
import { Quote, Change, Project } from '../../../models';
import type { QuoteItem } from '../../../models';
import {
    SelectField, TypeField, ChangeListItem,
    ChangePicker, Button, ClientSelectField,
    ToggleField, WysiwygField
} from '../../../components';

interface QuoteFormProps {
    isCreation: boolean,
    initialValues: FormikValues,
    onSubmit: (values: AnyObj) => void
}

function QuoteForm(props: QuoteFormProps) {
    const { isCreation, initialValues, onSubmit } = props;

    const { t, i18n } = useTranslation();
    const dispatch    = useAppDispatch();
    const navigate    = useNavigate();

    const { projects } = useAppSelector((state : RootState) => state.projects);
    const { updating } = useAppSelector((state: RootState) => state.quotes);

    useEffect(()=>{
        dispatch(loadProjects());
    },[dispatch]);

    const quote = new Quote(initialValues);

    const validate = (values: AnyObj) => {
        const invalid: AnyObj = {};

        if (!values.name)
            invalid.name = t('common.validation.this_field_is_required');

        if (!values.project)
            invalid.project = t('common.validation.this_field_is_required');

        return invalid;
    }

    const filterAvailableChanges= (items: Change[], values: AnyObj) => {
        return items.filter((change: Change) => {
            if (!change.isQuoteable())
                return false;

            return !values.items.find((item: QuoteItem) => item.change?._id === change._id);
        });
    };

    const projectOptions = React.useMemo(() => {
        return projects?.map((project: Project) => ({
            value: project._id,
            label: project.name
        }));
    }, [projects]);

    const onSaveQuoteItemDescription = (value: string, index: number) => {
        const items = quote.items;
        items[index].description = value;

        return dispatch(updateQuote({ _id: quote._id, items }, true));
    };

    return (
        <div className="QuoteForm bg-white border-b px-4 py-6 sm:px-6 lg:px-8">
            <main>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validate={validate}
                    onSubmit={(values, { setSubmitting }) => {
                        onSubmit(values);
                    }}
                >
                    {({ values, handleSubmit }) => (
                        <Form>
                            <div className="space-y-6">
                                <div className="flex flex-col space-y-4 justify-center">
                                    <div className="grid grid-cols-1 gap-y-2 md:grid-cols-12">
                                        <div className="col-span-10 lg:col-span-5">
                                            <label htmlFor="name" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                {t('quotes.name')}
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    type="text"
                                                    name="name"
                                                    className={`h-[42px] text-center block w-full rounded-md ${!isCreation ? 'md:rounded-r-none' : ''} border-1 border-gray-300 py-1.5 text-gray-900 font-bold placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                                    required
                                                />
                                                <ErrorMessage name="name" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                            </div>
                                        </div>
                                        {!isCreation && (
                                            <div className="col-span-2 lg:col-span-1">
                                                <label htmlFor="slug" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                    {t('quotes.slug')}
                                                </label>
                                                <div className="mt-2">
                                                    <Field
                                                        type="text"
                                                        name="slug"
                                                        disabled
                                                        className="h-[42px] block w-full rounded-md bg-gray-100 border-gray-300 border-1 md:border-l-0 md:rounded-l-none md:rounded-r-none py-1.5 text-gray-900 font-bold placeholder:text-gray-400 sm:text-sm sm:leading-6 text-center"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-span-full lg:col-span-3">
                                            <label htmlFor="project" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                {t('quotes.project')}
                                            </label>
                                            {isCreation ? (
                                                <div className="mt-2 ml-2 border rounded-md">
                                                    <SelectField
                                                        name="project._id"
                                                        placeholder={t('changes.choose_a_project') || ''}
                                                        options={projectOptions}
                                                    />
                                                    <ErrorMessage name="project" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                </div>
                                            ) : (
                                                <div className="mt-2">
                                                    <Field
                                                        type="text"
                                                        name="project.name"
                                                        disabled
                                                        className="h-[42px] block w-full rounded-md bg-gray-100 border-gray-300 border-1 md:border-l-0 md:rounded-l-none py-1.5 text-gray-900 font-bold placeholder:text-gray-400 sm:text-sm sm:leading-6 text-center"
                                                        required
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-span-full lg:col-span-3">
                                            <label htmlFor="client" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                {t('quotes.client')}
                                            </label>
                                            <div className="mt-2 ml-2">
                                                <ClientSelectField
                                                    name="client"
                                                    placeholder={t('changes.choose_a_client') || ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {!isCreation && (
                                    <div className="mt-5">
                                        <h4 className="text-center text-xl font-bold leading-7 text-gray-900">
                                            {t('quotes.total_price', {total: quote.formattedPrice()})}
                                        </h4>
                                        <h4 className="mb-4 text-center font-light leading-7 text-gray-900">
                                            {t('quotes.total_estimated', {total: quote.totalEstimated().toFixed(2)})}
                                        </h4>
                                        <hr />
                                        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-3 lg:grid-cols-4">
                                            <FieldArray name="items">
                                                {({ insert, remove, push, pop }) => (
                                                    <>
                                                        {values.items.length > 0 && values.items.map((item: AnyObj, i: number) => (
                                                            <div key={i} className="col-span-2 border my-2 rounded bg-white hover:bg-gray-50">
                                                                <div className="grid grid-cols-1 gap-x-2 gap-y-2 p-3">
                                                                    <div className="col-span-1">
                                                                        <Field
                                                                            type="text"
                                                                            name={`items[${i}].title`}
                                                                            placeholder={t('quotes.title_placeholder')}
                                                                            value={item.title}
                                                                            className="block w-full rounded-md border-gray-300 py-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-1">
                                                                        <WysiwygField
                                                                            rows={6}
                                                                            name={`items[${i}].description`}
                                                                            className="block w-full rounded-md border-gray-300 py-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                                            editOnClick
                                                                            hideButtons
                                                                            onSave={(value: string) => onSaveQuoteItemDescription(value, i)}
                                                                            isLoading={ updating === 'pending' }
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-1 p-2 bg-gray-100 rounded-md border">
                                                                        <TypeField name={`items[${i}].type`} />
                                                                    </div>
                                                                    <div className="col-span-1">
                                                                        <div className="grid grid-cols-1 md:grid-cols-8 gap-x-0 gap-y-4 py-3">
                                                                            <div className="col-span-3 flex flex-row">
                                                                                <Field
                                                                                    type="number"
                                                                                    step={0.5}
                                                                                    name={`items[${i}].estimate`}
                                                                                    value={item.estimate}
                                                                                    className="block w-full rounded-md rounded-r-none border-gray-300 py-1 font-bold text-center text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                                                />
                                                                                <div className="w-16 text-center bg-gray-300 px-1 py-1 rounded-r-md md:rounded-none text-gray-900 font-bold sm:text-sm sm:leading-6">h</div>
                                                                            </div>
                                                                            <div className="col-span-3 flex flex-row">
                                                                                <Field
                                                                                    type="number"
                                                                                    step={0.01}
                                                                                    name={`items[${i}].price`}
                                                                                    value={item.price}
                                                                                    className="block w-full rounded-l-md md:rounded-none md:border-l-0 font-bold text-center border-gray-300 py-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                                                    required
                                                                                />
                                                                                <div className="w-16 text-center rounded-r-md md:rounded-none bg-gray-300 px-1 py-1 text-gray-600 font-bold sm:text-sm sm:leading-6">CHF</div>
                                                                            </div>
                                                                            <div className="col-span-2 flex flex-row border rounded-r-lg">
                                                                                <div className="flex justify-center items-center px-2 w-full">
                                                                                    <ToggleField name={`items[${i}].free`} />
                                                                                </div>
                                                                                <div className="flex justify-center items-center px-2 font-bold text-gray-600 text-sm bg-gray-300 rounded-r-lg">
                                                                                    {t('quotes.free_item')}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {item.change ? (
                                                                        <>
                                                                            <div className="font-bold text-sm">
                                                                                {t('quotes.this_item_is_linked_to_this_change')}
                                                                            </div>
                                                                            <div onClick={() => navigate(`/projects/${quote.project?._id}/change/${item.change._id}`)}>
                                                                                <ChangeListItem
                                                                                    change={item.change}
                                                                                    onRemove={(change: Change, e: AnyObj) => {
                                                                                        e.stopPropagation();
                                                                                        item.change = undefined;
                                                                                        push({});
                                                                                        pop();
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="font-bold text-sm">
                                                                                {t('quotes.choose_a_change')}
                                                                            </div>
                                                                            <ChangePicker
                                                                                defaultProjectId={values?.project?._id}
                                                                                onSelect={(change: Change, e: AnyObj) => {
                                                                                    item.type        = change.type;
                                                                                    item.title       = change.localizedTitle(i18n.language);
                                                                                    item.description = change.localizedDescription(i18n.language);
                                                                                    item.change      = change;
                                                                                    item.estimate    = change.estimate;
                                                                                    push({});
                                                                                    pop();
                                                                                }}
                                                                                className="block w-full"
                                                                                listClassName="max-h-[400px] overflow-auto"
                                                                                selectClassName="max-h-[400px] overflow-auto"
                                                                                filterChanges={(changes: Change[]) => filterAvailableChanges(changes, values)}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className="cursor-pointer flex justify-center items-center mr-2 mb-2">
                                                                    <Button
                                                                        onClick={() => remove(i)}
                                                                        title={t('quotes.remove_this_item')}
                                                                        color="danger"
                                                                        icon="delete"
                                                                        small
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="col-span-1 md:col-span-3 lg:col-span-4 flex justify-center">
                                                            <Button
                                                                onClick={() => push({
                                                                    title: '',
                                                                    type: Change.TYPE_FEATURE,
                                                                    price: 0,
                                                                    estimate: 0
                                                                })}
                                                                title={t('quotes.add_item')}
                                                                color="navigate"
                                                                icon="plus"
                                                                small
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <Button to={`/quotes/${quote?._id}`} title={t('common.back')} color="navigateBack" icon="back" />
                                <Button type="submit" title={t('common.save')} color="primary" icon={updating === 'pending' ? 'loading' : 'save'} />
                            </div>
                        </Form>
                    )}
                </Formik>
            </main>
        </div>
    );
}

export default QuoteForm;
