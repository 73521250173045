import React from 'react';
import { useTranslation } from 'react-i18next';
import { Draggable } from "react-beautiful-dnd";
import { CopyButton, Icon, UserAvatar, ChangeTrack, Tooltip } from '../../../components';
import { Change, Commit } from '../../../models';
import { useAppSelector } from '../../../redux';
import { Badge, Progress } from 'flowbite-react';
import moment from 'moment';

interface CardProps {
    change: Change;
    isSmart?: boolean;
    expanded?: boolean;
    index: number;
    onEdit: (change: Change) => void;
}

const Card = (props: CardProps) => {
    const { t, i18n } = useTranslation();
    const { change, isSmart, expanded, index, onEdit } = props;

    const progression = Math.round(change.progression());

    const { user }   = useAppSelector(state => state.auth);
    const { active } = useAppSelector(state => state.tracks);

    const currentChange = useAppSelector(state => state.changes.change);

    const content = React.useMemo(() => {
        if (!change)
            return null;

        const ch : Change = currentChange?._id === change._id ? currentChange : change;

        return (
            <>
                <div className="border-b order-gray-100 bg-gray-50 px-2 py-1 flex justify-between items-center rounded-t-lg">
                    <div className="flex space-x-2">
                        <Tooltip content={t("changes.badge.slug")}>
                            <div>
                                <Badge color={ch.color()} className="w-fit text-xs px-1">
                                    <div className="flex">
                                        <Icon type={ch.iconName()} size={3} className="inline" />&nbsp;
                                        <CopyButton title={ ch.slugName() } textToCopy={ ch.slugName() } color="none" hover className="w-[40px]" />
                                    </div>
                                </Badge>
                            </div>
                        </Tooltip>
                        {(ch.nbComments > 0) && (
                            <Tooltip content={t("changes.badge.comment")}>
                                <div>
                                    <Badge color="indigo" className="w-fit text-xs px-1">
                                        <Icon type="comment" className="inline" />&nbsp;
                                        {ch.nbComments}
                                    </Badge>
                                </div>
                            </Tooltip>
                        )}
                        {ch.issue?.id && (
                            <Tooltip content={t(`changes.badge.issue_triggered_by_${ch.issue.source}`)}>
                                <div>
                                    <Badge color="dander" className="w-fit text-xs px-1">
                                        <Icon type={ ch.issueIconName() } className="inline" />&nbsp;
                                    </Badge>
                                </div>
                            </Tooltip>
                        )}
                        {ch.nbAttachments > 0 && (
                            <Tooltip content={t("changes.badge.attachment")}>
                                <div>
                                    <Badge color="gray" className="w-fit text-xs px-1">
                                        <Icon type="file" className="inline" />&nbsp;
                                        {ch.nbAttachments}
                                    </Badge>
                                </div>
                            </Tooltip>
                        )}
                        {ch.developer_notes && (
                            <Tooltip content={t("changes.badge.developer_notes")}>
                                <div>
                                    <Badge color="indigo" className="w-fit text-xs px-1">
                                        <Icon type="notes" className="inline" />
                                    </Badge>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    {ch.assignee && !ch.isTimeTrackable(user) && (
                        <Tooltip content={ch.assignee?.firstname + ' ' + ch.assignee?.lastname}>
                            <div>
                                <UserAvatar size={6} user={ch.assignee} noTooltip className="my-1" />
                            </div>
                        </Tooltip>
                    )}
                    {ch.assignee && ch.isTimeTrackable(user) && (
                        <div className="group">
                            <div className="h-8 w-8 flex justify-center items-center">
                                {active?.change?._id === ch._id ? (
                                    <ChangeTrack className="group-hover:hidden" change={ch} />
                                ) : (
                                    <UserAvatar size={6} user={ch.assignee} noTooltip className="group-hover:hidden my-1"/>
                                )}
                                <ChangeTrack className="hidden group-hover:block" change={ch} />
                            </div>
                        </div>
                    )}
                    {!ch.assignee && (
                        <div className="w-fit h-[26px]"></div>
                    )}
                </div>
                {progression > 0 && (
                    <div className="p-1 h-4">
                        <Tooltip content={progression + '%'}>
                            <div>
                                <Progress
                                    progress={progression}
                                    size="sm"
                                    color={ progression === 100 ? 'green' : (progression > 20 ? 'cyan' : 'yellow')}
                                />
                            </div>
                        </Tooltip>
                    </div>
                )}
                <h4 className="text-sm font-normal heading-md my-1 px-2">
                    {ch.title[i18n.language]}
                </h4>
                <div className="flex justify-between text-sm font-normal p-2 border-t">
                    <Tooltip content={t("changes.badge.priority")}>
                        <div>
                            <Badge color="indigo" className="w-fit" size="xs">
                                <Icon type="priority" className="inline mr-1" />
                                {ch.priority}
                            </Badge>
                        </div>
                    </Tooltip>
                    {ch.quote && (
                        <Tooltip content={t("changes.badge.quote")}>
                            <div>
                                <Badge color="blue" className="w-fit text-xs px-1">
                                    <Icon type="law" className="inline" />&nbsp;
                                    {ch.quote.slug} | {t(`quotes.status_type.${ch.quote.status}`)}
                                </Badge>
                            </div>
                        </Tooltip>
                    )}
                </div>
                <div className="flex justify-between space-x-1 p-2 py-1 rounded-b-lg border-t">
                    <Tooltip content={t("changes.badge.status")}>
                        <div className="text-xs font-light border border-gray-100 rounded px-2 whitespace-nowrap flex flex-row justify-between">
                            <span className={`whitespace-nowrap text-ellipsis overflow-hidden truncate inline-block ${ch.estimate > 0 ? 'max-w-[9rem]' : ''}`}>
                                <Icon type="progress" size={3} className="inline mr-1"/>&nbsp;
                                {t(`changes.progress_status_type.${ch?.progress?.status}`)}
                            </span>
                        </div>
                    </Tooltip>
                    {(ch.estimate > 0) && (
                        <Tooltip content={t("changes.badge.estimated_time")}>
                            <div>
                                <Badge color="warning" className="w-fit whitespace-nowrap px-1">
                                    <>
                                        <Icon type="estimated" size={3} className="inline mr-1"/>&nbsp;
                                        {ch?.estimate} {t('changes.estimate_units_min')}
                                    </>
                                </Badge>
                            </div>
                        </Tooltip>
                    )}
                </div>
                {(ch.commits?.length > 0) && (
                    <Tooltip content={t("changes.badge.commit")}>
                        <div className="flex-col justify-center space-y-1 p-2 py-1 rounded-b-lg border-t">
                            {ch.commits?.map((commit: Commit) => (
                            <Badge key={commit.hash} color="success" className="w-fit px-1">
                                <Icon type="commit" size={3} className="inline"/>&nbsp;
                                {commit.message}
                            </Badge>
                            ))}
                        </div>
                    </Tooltip>
                )}
                {(ch.deadline || ch.sprints?.length > 0) && (
                    <div className="flex justify-between p-2 rounded-b-lg border-t">
                        {(ch.sprints?.length > 0) ? (
                            <Tooltip content={t("changes.badge.sprint")}>
                                <div>
                                    <Badge color="warning" size="xs" className="w-fit px-1">
                                        <span className="text-xs font-normal">
                                            <Icon type="sprint" color="indigo" className="inline mr-1.5"/>
                                            {ch.sprints.slice(-1)[0].name}
                                        </span>
                                    </Badge>
                                </div>
                            </Tooltip>
                        ) : (
                            <div></div>
                        )}
                        {ch.deadline && (
                            <Tooltip content={t("changes.badge.deadline")}>
                                <div>
                                    <Badge color={ch.deadline.isBefore(moment().startOf('day')) ? 'pink' : (ch.deadline.isAfter(moment()) ? 'green' : 'indigo')} size="xs" className="w-fit px-1">
                                        <span className="font-normal">
                                            <Icon type="date" color="indigo" className="inline mr-1.5"/>
                                            {ch.deadline.locale(i18n.language).format('L')}
                                        </span>
                                    </Badge>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                )}
                {ch.release && (
                    <Tooltip content={t("changes.badge.version")}>
                        <div className="flex justify-change-center align-center text-lg p-2 rounded-b-lg border-t">
                            <Badge color="indigo" size="sm" className="flex-1 w-fit flex space-x-3 justify-center px-1">
                                <Icon type="release" color="indigo" className="inline mr-1.5 -ml-2"/>
                                {ch.release.version}
                            </Badge>
                        </div>
                    </Tooltip>
                )}
            </>
        );
    }, [(currentChange?._id && currentChange._id === change?._id && currentChange.updatedAt) || (active?.change?._id === change?._id)]);

    if (expanded || isSmart)
        return (
            <li
                className={`select-none mb-1 ${expanded ? 'col-span-1 h-full' : ''}`}
                onClick={() => onEdit(change)}
            >
                <div className={"h-full flex flex-col justify-between border border-gray-300 hover:border-gray-400 rounded-lg cursor-pointer bg-white text-black dark:bg-darkGrey dark:text-white min-h-max"}>
                    { content }
                </div>
            </li>
        );

    return (
        <Draggable draggableId={change._id} index={index} >
            { (provided?: AnyObj, snapshot?: AnyObj) => (
                <>
                    <li
                        className={`select-none mb-1 ${expanded ? 'col-span-1 h-full' : ''}`}
                        {...(provided?.draggableProps || {})}
                        {...(provided?.dragHandleProps || {})}
                        ref={provided?.innerRef}
                        onClick={() => onEdit(change)}
                    >
                        <div className={`${snapshot?.isDragging && !snapshot?.isDropAnimating ? 'rotate-[5deg]' : ''} h-full flex flex-col justify-between border border-gray-300 hover:border-gray-400 rounded-lg cursor-pointer bg-white text-black dark:bg-darkGrey dark:text-white min-h-max`}>
                            { content }
                        </div>
                    </li>
                    {provided?.placeholder}
                </>
            )}
        </Draggable>
    )
};

export default Card;
