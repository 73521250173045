import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, Release } from '../../models';
import { useAppDispatch, useAppSelector, RootState } from '../../redux';
import { loadReleases } from '../../redux';
import { Loader, ChangesList } from '../../components';
import { Select } from 'flowbite-react';

interface ProjectReleasesProps {
    project: Project;
}

function ProjectReleases(props: ProjectReleasesProps) {
    const { project } = props;

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();

    const [releaseId, setReleaseId] = useState();

    const { loading, releases } = useAppSelector((state : RootState) => state.releases);

    useEffect(() => {
        dispatch(loadReleases({project: project._id}));
    }, [dispatch]);

    const onReleaseChange = (e: AnyObj) => {
        setReleaseId(e.target.value);
    }

    // select first release _id by default
    if (!releaseId && releases?.length > 0)
        setReleaseId(releases[0]._id);

    const release = releases?.find((r: Release) => r._id === releaseId);

    return (
        <div className="ProjectReleases flex flex-col gap-2 mt-2">
            <div className="flex justify-center items-center">
                <Select onChange={onReleaseChange} value={release?._id}>
                    <option>{t('changes.choose_a_release')}</option>
                    {releases?.map((release: Release) => (
                        <option key={release._id} value={release._id}>
                            V. {release.version}
                        </option>
                    ))}
                </Select>
            </div>
            <div className="flex">
                {(loading === 'pending') && (
                    <Loader />
                )}
                {release?.changes?.length > 0 && (loading !== 'pending') && (
                    <ChangesList changes={release.changes} withHeader hideStatut />
                )}
            </div>
        </div>
    );
}

export default ProjectReleases;
