import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import type { FormikValues } from 'formik';
import { useNavigate } from "react-router-dom";
import {RootState, useAppSelector, useAppDispatch, loadChange, resetChanges, updateGoal, updateChange} from '../../../redux';
import { loadProjects } from '../../../redux';
import { Goal, Change, Project, Sprint } from '../../../models';
import {
    MultiSelectField, TypeField, ChangeListItem,
    ChangePicker, Button, ClientSelectField, ChangeModal,
    ToggleField, WysiwygField, DatepickerField
} from '../../../components';
import type { Interval } from '../../../components';

interface GoalFormProps {
    isCreation: boolean,
    initialValues: FormikValues,
    onSubmit: (values: AnyObj) => void
}

function GoalForm(props: GoalFormProps) {
    const { isCreation, initialValues, onSubmit } = props;

    const { t }    = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [currentChangeId, setCurrentChangeId]           = useState<string | undefined>();
    const [changesPickerVisible, setChangesPickerVisible] = useState(!(initialValues?.changes.length > 0));

    const { projects } = useAppSelector((state : RootState) => state.projects);
    const { updating } = useAppSelector((state: RootState) => state.goals);
    const { change }   = useAppSelector(state => state.changes);

    useEffect(() => {
        dispatch(loadProjects());
    },[dispatch]);

    const goal = new Goal(initialValues);
    if (initialValues)
        initialValues.projects = initialValues.projects?.map((p: Project) => p?._id || p);

    const selectChange = ((change?: Change) => {
        setCurrentChangeId(change?._id);
        if (change)
            dispatch(loadChange(change._id));
        else
            dispatch(resetChanges(/*onlyItem*/true));
    });

    const validate = (values: AnyObj) => {
        const invalid: AnyObj = {};

        if (!values.name)
            invalid.name = t('common.validation.this_field_is_required');

        return invalid;
    }

    const addChange = async (change: Change, callback: () => void) => {
        const params = {
            _id:  change._id,
            goals: [
                ...(change.goals || []),
                goal._id
            ]
        };

        await dispatch(updateChange(params, /*patch*/true, /*optimistic*/false, callback));
    };

    const removeChange = async (change: Change, callback: () => void) => {
        if (!change)
            return;

        const params = {
            "_id":  change._id,
            "goals": change.goals.filter((g: Goal) => g._id !== goal._id).map((g: Goal) => g._id)
        };

        await dispatch(updateChange(params, /*patch*/true, /*optimistic*/false, callback));
    }

    const filterAvailableProjects= (projects: Project[], values: AnyObj) => {
        return projects.filter((project: Project) => {
            if (values.projects.find((p: string) => p === project._id))
                return true;

            return false;
        });
    };

    const filterAvailableChanges= (changes: Change[], values: AnyObj) => {
        return changes.filter((change: Change) => {
            if (values.changes.find((c: Change) => c._id === change._id))
                return false;

            return true;
        }).sort((a: Change, b: Change) => b.slug - a.slug);
    };

    const projectOptions = React.useMemo(() => {
        return projects?.map((project: Project) => ({
            value: project._id,
            label: project.name
        }));
    }, [projects]);

    const applyFilters = (value: Interval) => {
        /*setFilters({
            ...filters,
            from: value?.startDate.startOf('day'),
            to: value?.endDate.endOf('day')
        });*/
    };


    const renderChanges = (values: any, remove: any) => {
        if (!values.changes?.length)
            return null;

        const sortedGroups = Sprint.groupChangesByProject(Sprint.sortChangesBy(values.changes, 'project'));

        return (
            <div className={`col-span-${changesPickerVisible ? 2 : 3} flex flex-col space-y-2`}>
                {Object.keys(sortedGroups).map((projectId: string) => (
                    <div key={projectId}>
                        <h3 className="font-bold text-lg">{sortedGroups[projectId].name}</h3>
                        <div className={`grid grid-cols-2 grid-flow-row auto-rows-max gap-x-2 gap-y-2 mb-4`}>
                            {sortedGroups[projectId].changes.map((change: Change) => (
                            <div key={change._id} onClick={() => selectChange(change)} className={`col-span-2 lg:col-span-${changesPickerVisible ? 2 : 1}`} >
                                <ChangeListItem
                                    showProject
                                    onRemove={(change: Change, e: AnyObj) => {
                                        e.stopPropagation();
                                        removeChange(change, () => remove(change));
                                    }}
                                    change={change}
                                />
                            </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
    return (
        <div className="GoalForm bg-white border-b px-4 py-6 sm:px-6 lg:px-8">
            <main>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validate={validate}
                    onSubmit={(values, { setSubmitting }) => {
                        onSubmit(values);
                    }}
                >
                    {({ values, handleSubmit }) => (
                        <Form>
                            <div className="space-y-6">
                                <div className="flex flex-col space-y-4 justify-center">
                                    <div className="grid grid-cols-1 gap-y-2 md:grid-cols-12">
                                        <div className="col-span-12 lg:col-span-6">
                                            <label htmlFor="name" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                {t('goals.name')}
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    type="text"
                                                    name="name"
                                                    className={`h-[42px] text-center block w-full rounded-md ${!isCreation ? 'md:rounded-r-none' : ''} border-1 border-gray-300 py-1.5 text-gray-900 font-bold placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                                    required
                                                />
                                                <ErrorMessage name="name" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                            </div>
                                        </div>
                                        <div className="col-span-12 lg:col-span-6">
                                            <label htmlFor="projects" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                {t('goals.projects')}
                                            </label>
                                            <div className="mt-2 ml-2 border rounded-md">
                                                <MultiSelectField
                                                    name="projects"
                                                    placeholder={t('changes.choose_a_project') || ''}
                                                    options={projectOptions}
                                                />
                                                <ErrorMessage name="projects" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                            </div>
                                        </div>
                                        <div className="col-span-12 lg:col-span-6">
                                            <label htmlFor="startAt" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                {t('goals.startAt')}
                                            </label>
                                            <div className="flex items-center justify-center mt-2">
                                                <DatepickerField name="startAt" unclearable />
                                            </div>
                                        </div>
                                        <div className="col-span-12 lg:col-span-6">
                                            <label htmlFor="stopAt" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                {t('goals.stopAt')}
                                            </label>
                                            <div className="flex items-center justify-center mt-2">
                                                <DatepickerField name="stopAt" unclearable />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <FieldArray name="changes">
                                    {({ remove, push, pop }) => (
                                        <div className="flex flex-col space-y-4 justify-center">
                                            <div className="grid grid-cols-1 gap-x-4 gap-y-2 lg:grid-cols-3">
                                                {changesPickerVisible && (
                                                    <div className="col-span-1">
                                                        <label htmlFor="name" className="block text-center text-sm font-medium leading-6 text-gray-900">
                                                            {t('goals.changes')}
                                                        </label>
                                                        <div className="mt-2">
                                                            <ChangePicker
                                                                onSelect={(change: Change, e: AnyObj) => addChange(change, () => { push({}); pop(); })}
                                                                className="block w-full"
                                                                listClassName="max-h-[400px] overflow-auto"
                                                                selectClassName="max-h-[400px] overflow-auto"
                                                                filterProjects={(projects: Project[]) => filterAvailableProjects(projects, values)}
                                                                filterChanges={(changes: Change[]) => filterAvailableChanges(changes, values)}
                                                                acceptUnestimated
                                                                acceptFinished={true}
                                                            />
                                                            <ErrorMessage name="name" component="div" className="mt-1 py-1 px-2 text-sm text-white bg-red-600 rounded"/>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={`col-span-${changesPickerVisible ? 2 : 3} flex flex-col space-y-2`}>
                                                    {renderChanges(values, remove)}
                                                </div>
                                            </div>
                                            {!changesPickerVisible && (
                                                <div className="col-span-1 mt-2 md:col-span-3 lg:col-span-4 flex justify-center">
                                                    <Button
                                                        onClick={() => { setChangesPickerVisible(true); push({}); pop(); }}
                                                        title={t('sprints.add_cards')}
                                                        color="navigate"
                                                        icon="plus"
                                                        small
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </FieldArray>
                            </div>

                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <Button to={`/goals/${goal?._id}`} title={t('common.back')} color="navigateBack" icon="back" />
                                <Button type="submit" title={t('common.save')} color="primary" icon={updating === 'pending' ? 'loading' : 'save'} />
                            </div>

                            {change && change._id === currentChangeId && change.project?.name && (
                                <ChangeModal
                                    project={change.project}
                                    change={change}
                                    show={true}
                                    showProjectName={true}
                                    onClose={() => selectChange()}
                                    onRemove={(values: AnyObj) => { }}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </main>
        </div>
    );
}

export default GoalForm;
