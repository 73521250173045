import BaseModel from './BaseModel';
import { Users } from '../services';
import moment from 'moment';
import type { Moment } from 'moment';
import md5 from "crypto-js/md5";

/** Class representing a user. */
class User extends BaseModel {
    _id: string;
    email: string;
    firstname: string;
    lastname: string;
    avatar: string;
    phone?: string;
    role: string;
    createdAt?: Moment;
    updatedAt?: Moment;
    token?: string;
    password?: string;
    favoriteProjects?:Array<string>;
    work_rate: number;

    _abilities: string[];

    constructor(properties: AnyObj) {
        super({});

        this._id              = properties._id;
        this.email            = properties.email;
        this.firstname        = properties.firstname;
        this.lastname         = properties.lastname;
        this.phone            = properties.phone;
        this.avatar           = properties.avatar;
        this.role             = properties.role;
        this.password         = properties.password;
        this.favoriteProjects = properties.favoriteProjects;
        this.work_rate        = properties.work_rate || 0;
        if ('createdAt' in properties)
            this.createdAt = moment(properties.createdAt);
        if ('updatedAt' in properties)
            this.updatedAt = moment(properties.updatedAt);

        this._abilities = [];
        this.computeAbilities();
    }

    static GENDER_DEFAULT = '?';
    static GENDER_FEMALE  = 'F';
    static GENDER_MALE    = 'M';

    static ROLE_ADMIN    = 'admin';
    static ROLE_USER     = 'user';
    static ROLE_EXTERNAL = 'external';
    static ROLE_CLIENT   = 'client';

    static roles() : string[] {
        return [
            User.ROLE_ADMIN,
            User.ROLE_USER,
            User.ROLE_EXTERNAL,
            User.ROLE_CLIENT,
        ];
    };

    username() {
        return this.email.split('@')[0];
    }

    fullname() {
        return [ this.firstname, this.lastname ]
            .filter(s => !!s)
            .join(' ');
    }

    rfullname() {
        return [ this.lastname, this.firstname ]
            .filter(s => !!s)
            .join(' ');
    }

    getInitials() {
        return this.fullname().match(/(\b\S)?/g)?.join("")?.match(/(^\S|\S$)?/g)?.join("");
    }

    /**
     * Get this user avatar url
     *
     * @return {String}
     */
    getAvatarUrl(size: number = 80, noCache: boolean = false) {
        if (this.avatar?.length > 0)
            return [
                Users.baseUrl,
                Users.entryPoint,
                this._id,
                'avatar'
            ].join('/') + (noCache ? '?t=' + Date.now() : '');

        return `https://secure.gravatar.com/avatar/${md5(this.email?.toLowerCase().trim())}?s=${size}&d=identicon`;
    }

    can(ability: string): boolean {
        return this._abilities.includes(ability);
    }

    computeAbilities() {
        this._abilities = [];

        // attachments
        this.allow('attachment:create', User.roles());
        this.allow('attachment:edit', User.roles());
        this.allow('attachment:delete', User.roles());
        this.allow('attachment:read', User.roles());

        // boards
        this.allow('board:create', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('board:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('board:delete', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('board:read', User.roles());

        // changes
        this.allow('change:create', User.roles());
        this.allow('change:edit', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('change:start', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('change:delete', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('change:delete:own', User.roles());
        this.allow('change:type:edit', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('change:read', User.roles());
        this.allow('change:title:edit', User.roles());
        this.allow('change:notes:edit', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('change:description:edit', User.roles());
        this.allow('change:client:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('change:importance:edit', User.roles());
        this.allow('change:ticket:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('change:support:read', [User.ROLE_ADMIN, User.ROLE_USER]);

        this.allow('change:estimate', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('change:plan', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('change:assign', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('change:assign_self', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);

        // clients
        this.allow('client:read', [User.ROLE_ADMIN, User.ROLE_USER]);

        // commits
        this.allow('commit:edit', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('commit:read', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);

        // documentation
        this.allow('documentation:create', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('documentation:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('documentation:delete', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('documentation:read', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);

        // logs
        this.allow('log:read', User.roles());

        // projects
        this.allow('project:create', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('project:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('project:delete', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('project:read', User.roles());
        this.allow('project:sync', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('project:members:read', User.roles());
        this.allow('project:members:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('project:reporting:read', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('project:checks:read', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);

        // goals
        this.allow('goal:create', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('goal:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('goal:delete', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('goal:read', [User.ROLE_ADMIN, User.ROLE_USER]);

        // quotes
        this.allow('quote:create', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('quote:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('quote:delete', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('quote:read', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_CLIENT]);
        this.allow('quote:changeStep', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('quote:accept', [User.ROLE_CLIENT]);

        // releases
        this.allow('release:read', User.roles());

        // sprints
        this.allow('sprint:create', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('sprint:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('sprint:delete', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('sprint:read', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);

        // support
        this.allow('support:create', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('support:edit', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('support:delete', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('support:read', User.roles());

        // tracks
        this.allow('track:create', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('track:edit', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('track:delete', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('track:read', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL]);
        this.allow('track:see_other', [User.ROLE_ADMIN]);

        // users
        this.allow('user:create', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('user:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('user:role:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('user:delete', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('user:read', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('user:logout', [User.ROLE_ADMIN, User.ROLE_USER, User.ROLE_EXTERNAL, User.ROLE_CLIENT]);

        // settings
        this.allow('setting:edit', [User.ROLE_ADMIN, User.ROLE_USER]);
        this.allow('setting:read', [User.ROLE_ADMIN, User.ROLE_USER]);
    }

    allow(ability: string, roles?: string[]) {
        if (!roles || roles?.includes(this.role))
            this._abilities.push(ability);
    }
}

export default User;
