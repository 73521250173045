import React, { useState } from 'react';
import { Icon } from '..';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface CopyButtonProps {
    textToCopy: string
    title?: string
    size?: number
    color?: 'none' | 'navigate' | 'navigateBack' | 'default' | 'primary' | 'secondary' | 'warning' | 'danger' | 'success'
    className?: string
    hover?: boolean
}

const CopyButton = (props: CopyButtonProps) => {

    const { textToCopy, title, size, color, className, hover } = props;
    const [hasCopied, setHasCopied] = useState(false);

    const onClick= (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setTimeout(() => setHasCopied(false), 1000);
    };

    return (
        <CopyToClipboard text={ textToCopy } onCopy={ () => setHasCopied(true) }>
            {hover ? (
                <div className={`group cursor-pointer ${className || ''}`} onClick={ onClick }>
                    <span className="group-hover:hidden">
                        { title }
                    </span>
                    <span className="hidden group-hover:block">
                        <Icon
                            type={ hasCopied ? 'check' : 'clipboard' }
                            size={ size || 4 }
                            color={ color || 'gray-900' }
                            className={className || ''}
                        />
                    </span>
                </div>
            ) : (
                <div className={`group flex flex-col items-center cursor-pointer gap-1 ${className || ''}`} onClick={ () => setTimeout(() => setHasCopied(false), 1000) }>
                    <span className="">
                        { title }
                    </span>
                    <span className="">
                        <Icon
                            type={ hasCopied ? 'check' : 'clipboard' }
                            size={ size || 4 }
                            color={ color || 'gray-900' }
                        />
                    </span>
                </div>
            )}
        </CopyToClipboard>
    );
}

export default CopyButton;
